import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { IDevice } from '@models/device';
import moment from 'moment';

@Component({
    selector: 'app-device-preview-activity',
    template: ` <div class="flex flex-col items-center" [matTooltip]="tooltip">
        <mat-icon
            svgIcon="logivice:sign_3"
            class="text-{{
                device.status === 'expired' ? 'red-600' : device.last_reply_on === null ? 'gray-500' : 'green-600'
            }}"
        ></mat-icon>
        <ng-container *ngIf="expanded && device.last_reply_on">
            <span class="text-xs">{{ device.last_reply_on | date : 'M/d/y h:mm a' }}</span>
            <span class="text-xs">{{ timeFromNow }}</span>
        </ng-container>
    </div>`
})
export class DevicePreviewActivityComponent implements OnChanges {
    @Input() device: IDevice;
    @Input() expanded = true;

    tooltip = null;
    timeFromNow: string;

    ngOnChanges(changes: SimpleChanges): void {
        if (!this.expanded) {
            const datetime = this.device.last_reply_on
                ? moment(this.device.last_reply_on).format('M/d/y h:mm a')
                : 'never';

            this.tooltip = 'Last active: ' + datetime;
        } else {
            if (this.device.last_reply_on) {
                this.timeFromNow = moment(this.device.last_reply_on).fromNow();
            }
        }
    }
}
