import { Injectable } from '@angular/core';
import { AuthDispatchers, AuthSelectors } from '@store/auth';
import { firstValueFrom, Subject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { IStepOption, TourService } from 'ngx-ui-tour-md-menu';

@Injectable({
    providedIn: 'root'
})
export class GuideService {
    restart = new Subject<void>();

    guideAvailable = true;

    runningGuides: string[] = [];

    constructor(
        private authSelectors: AuthSelectors,
        private authDispatchers: AuthDispatchers,
        private readonly tourService: TourService
    ) {
        this.tourService.end$.subscribe(() => {
            this.disableCurrentGuides();
        });
    }

    async checkGuideAvailability(guide: string): Promise<boolean> {
        return firstValueFrom(
            this.authSelectors.user$.pipe(
                take(1),
                map(user => user?.active_guides && user?.active_guides?.indexOf(guide) > -1)
            )
        );
    }

    async checkAndRunGuide(guide: string, steps: IStepOption[], forceRun = false): Promise<void> {
        const needToRun = forceRun || (await this.checkGuideAvailability(guide));

        if (needToRun) {
            this.queueGuide(guide, steps);
        }
    }

    // prepareGuide(guide: any): any {
    //     todo: (routes handling) include "..", ".", + add permissions (show only steps that user can see anchor)
    // }

    queueGuide(guideName: string, steps: IStepOption[]): void {
        const shouldStart = this.tourService.getStatus() === 0;

        if (this.runningGuides.indexOf(guideName) === -1) {
            this.runningGuides.push(guideName);

            if (shouldStart) {
                this.tourService.initialize(steps);
                this.tourService.start();
            } else {
                this.tourService.steps.push(...steps);
            }
        }
    }

    setGuideAvailable(value: boolean): void {
        this.guideAvailable = true;
    }

    private disableCurrentGuides(): void {
        this.authDispatchers.disableGuide(this.runningGuides);
        this.runningGuides = [];
    }
}
