import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthDispatchers } from '@store/auth/auth.dispatchers';
import { Subject } from 'rxjs';
import { FuseAlertType } from '@fuse/components/alert';
import { fuseAnimations } from '@animations/public-api';

@Component({
    selector: 'app-auth-login',
    templateUrl: './login.component.html',
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginComponent implements OnInit {
    twoFactor: Subject<boolean>;

    alert: { type: FuseAlertType; message: string } = {
        type: 'success',
        message: ''
    };
    showAlert: boolean = false;

    signInForm: FormGroup;

    constructor(private _formBuilder: FormBuilder, private authDispatchers: AuthDispatchers) {}

    ngOnInit(): void {
        this.signInForm = this._formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required],
            remember: [0]
        });
    }

    signIn(): void {
        const credentials: { email: string; password: string; remember: boolean } = this.signInForm.value;
        this.authDispatchers.login(credentials);
    }
}
