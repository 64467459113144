import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SoundService {
    audio = new Audio();

    constructor() {}

    notification(): void {
        this.audio.src = '../../../../assets/sounds/notification.wav';
        this.audio.load();
        this.audio.play();
    }
}
