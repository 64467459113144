import { createAction, props } from '@ngrx/store';
import { User } from '@models/user';
import { BroadcastChanelRequestType } from '@core/types/http/request.type';
import { ErrorResponseType } from '@core/types/http/respone.type';

export const login = createAction(
    '[Auth] Login',
    props<{ credentials: { email: string; password: string; remember: boolean } }>()
);
export const loginSuccess = createAction('[Auth] Login Success', props<{ two_factor: boolean }>());
export const loginFailure = createAction('[Auth] Login Failure', props<{ error: any }>());
export const loginRedirect = createAction('[Auth] Login Redirect');

export const logout = createAction('[Auth] Logout');
export const logoutSuccess = createAction('[Auth] Logout Success');

export const loadUser = createAction('[Auth] Load User');
export const loadUserSuccess = createAction('[Auth] Load User Success', props<{ user: User }>());
export const loadUserFailure = createAction('[Auth] Load User Failure', props<ErrorResponseType<Array<string>>>());

export const updateUser = createAction('[Auth] Update User', props<{ data: any }>());
export const updateUserSuccess = createAction('[Auth] Update User Success ', props<{ user: User }>());
export const updateUserFailure = createAction('[Auth] Update User Success ', props<ErrorResponseType<Array<string>>>());

export const updateUserProperties = createAction(
    '[Auth] Update User Props',
    props<{ properties: { [key: string]: any } }>()
);

export const twoFactorAuthRequired = createAction('[Auth] 2FA Required');
export const twoFactorSubmit = createAction('[Auth] 2FA Submit', props<{ data: any }>());
export const twoFactorSuccess = createAction('[Auth] 2FA Success');
export const twoFactorFailure = createAction('[Auth] 2FA Failure', props<{ error: any }>());

export const setToken = createAction('[Auth] Set Token', props<{ token: string }>());
export const invalidateToken = createAction('[Auth] Invalidate token');

export const disableGuides = createAction('[Auth] Disable Guides', props<{ names: string[] }>());

export const authBroadcast = createAction(
    '[Auth] Authorizing Broadcast',
    props<{ channel: BroadcastChanelRequestType }>()
);
export const authBroadcastSuccess = createAction(
    '[Auth] Authorizing Authorizing Broadcast Success',
    props<{ auth: string }>()
);
export const authBroadcastFailure = createAction(
    '[Auth] Authorizing Authorizing Broadcast Failure',
    props<{ error: any }>()
);
