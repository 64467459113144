import { Injectable, signal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { finalize, Observable } from 'rxjs';
import { SuccessResponseType } from '@core/types/http/respone.type';

@Injectable({
    providedIn: 'root'
})
export class ApiHttpService {
    processing = signal<string[]>([]);

    constructor(private http: HttpClient) {}

    public get<model>(url: string, options?: any): Observable<any> {
        this.start(url);

        return this.http.get<SuccessResponseType<model>>(url, options).pipe(finalize(() => this.finalize(url)));
    }

    public post<model>(url: string, data: any, options?: any): Observable<any> {
        this.start(`${url}:creating`);

        return this.http
            .post<SuccessResponseType<model>>(url, data, options)
            .pipe(finalize(() => this.finalize(`${url}:creating`)));
    }

    public put<model>(url: string, data: any, options?: any): Observable<any> {
        this.start(`${url}:updating`);

        return this.http
            .put<SuccessResponseType<model>>(url, data, options)
            .pipe(finalize(() => this.finalize(`${url}:updating`)));
    }

    public delete<model>(url: string, options?: any): Observable<any> {
        return this.http.delete<SuccessResponseType<model>>(url, options);
    }

    private start(url: string): void {
        this.processing.update(urls => [...urls, url]);
    }

    private finalize(url: string): void {
        this.processing.update(urls => urls.filter(u => u !== url));
    }
}
