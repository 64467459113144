export const filterByOptions = [
    {
        param: 'created_at',
        title: 'Creation date'
    },
    {
        param: 'updated_at',
        title: 'Update date'
    },
    {
        param: 'timeline',
        title: 'Timeline'
    }
];

export const searchByOptions = [
    {
        title: 'Title',
        param: 'title'
    },
    {
        title: 'Ticket #',
        param: 'lticket'
    },
    {
        title: 'Created by',
        param: 'creator.name'
    },
    {
        title: 'Assignee',
        param: 'assignee.name'
    },
    {
        title: 'Order #',
        param: 'order.ref'
    }
];

export const filterByPriorityOptions = [
    {
        title: 'Low',
        bg: 'bg-green-200',
        icon: 'heroicons_outline:arrow-down'
    },
    {
        title: 'Normal',
        bg: 'bg-gray-200',
        icon: 'heroicons_outline:minus'
    },
    {
        title: 'High',
        bg: 'bg-orange-200',
        icon: 'heroicons_outline:arrow-up'
    },
    {
        title: 'Critical',
        bg: 'bg-red-200',
        icon: 'heroicons_outline:exclamation-circle'
    },
    {
        title: 'All',
        bg: 'bg-card',
        icon: 'heroicons_outline:view-grid'
    }
];
