/* eslint-disable */
export const iconMapping = {
    shipping_mode: {
        courier: 'logivice:courier_track',
        seafreight: 'logivice:ocean_ship',
        airfreight: 'logivice:freight_plane',
    },
    courier_source: {
        aftership: 'logivice:courier_track',
        shipsgo: 'logivice:ocean_ship',
        champ: 'logivice:freight_plane',
        flight: 'logivice:freight_plane',
    },
    courier_source_pin: {
        aftership: 'logivice:courier_track_pin',
        shipsgo: 'logivice:ocean_ship_pin',
        champ: 'logivice:freight_plane_pin',
        flight: 'logivice:freight_plane_pin',
    },
    shipment_status: {
        'created': 'mat_outline:info',
        'shipped': 'mat_outline:local_shipping',
        'partially-shipped': 'mat_outline:schedule_send',
        'delayed': 'mat_outline:warning_amber',
        'delivered': 'mat_outline:done_all',
        'partially-delivered': 'mat_outline:done',
        'partially-arrived': 'mat_outline:done',
    },
    item_batch: {
        serial: 'mat_outline:ballot',
        box: 'feather:package',
        pallet: 'mat_outline:space_bar'
    }
};
