import { createAction, props } from '@ngrx/store';
import { IMentionUser } from '@models/user';
import { Scheme } from '@store/app-core/app.themes';

export const changeOrientation = createAction(
    '[APP CORE] CHANGE_ORIENTATION',
    props<{ orientation: 'horizontal' | 'vertical' }>()
);
export const changeTheme = createAction('[APP CORE] CHANGE_THEME', props<{ theme: string }>());
export const changeScheme = createAction('[APP CORE] CHANGE_SCHEME', props<{ scheme: Scheme; silent: boolean }>());
export const changeNavbar = createAction(
    '[APP CORE] CHANGE_NAVBAR',
    props<{
        navbar: { primaryBackground?: string; secondaryBackground?: string; folded?: boolean; hidden?: boolean };
    }>()
);

export const changeMetainfo = createAction(
    '[APP CORE] CHANGE_METAINFO',
    props<{
        metainfo: {
            subject?: string;
            logo?: string;
            icon?: string;
            type?: 'uops' | 'threepl' | 'cockpit' | 'ucontrol' | null;
            slug?: string;
        };
    }>()
);
export const navigateToDashboard = createAction('[APP CORE] NAVIGATE_TO_DASHBOARD');
export const navigateToUrl = createAction('[APP CORE] NAVIGATE_TO_URL', props<{ url: string }>());

export const setNavigation = createAction(
    '[APP CORE] SET_NAVIGATION',
    props<{ navigation: 'partner' | 'threepl' | 'cockpit' }>()
);

export const toggleNavbar = createAction('[APP CORE] TOGGLE_NAVBAR');
export const changeToolbar = createAction(
    '[APP CORE] CHANGE_TOOLBAR',
    props<{ toolbar: { background?: string; hidden?: boolean } }>()
);
export const changeSidepanel = createAction(
    '[APP CORE] CHANGE_SIDEPANEL',
    props<{ sidepanel: { hidden?: boolean } }>()
);

export const loadMentions = createAction('[APP CORE] LOAD_MENTIONS');
export const loadMentionsSuccess = createAction(
    '[APP CORE] LOAD_MENTIONS_SUCCESS',
    props<{ mentions: IMentionUser[] }>()
);
export const loadMentionsFailure = createAction('[APP CORE] LOAD_MENTIONS_FAILURE');

export const setTitleVars = createAction('[APP CORE] SET_TITLE_VARS', props<{ vars: object }>());
