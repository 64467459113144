import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';
import { NgxPermissionsService } from 'ngx-permissions';
import { SettingsDispatchers, SettingsSelectors } from '@store/settings';
import { IOrganisation } from '@models/organisation';

@Injectable({
    providedIn: 'root'
})
export class OrganisationActivateGuard implements CanActivate {
    constructor(
        private settingsSelectors: SettingsSelectors,
        private settingsDispatchers: SettingsDispatchers,
        private permissionsService: NgxPermissionsService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const organisation = route.params.organisation;
        return this.checkStore(organisation).pipe(
            switchMap(() => of(true)),
            catchError(() => of(false))
        );
    }

    checkStore(slug: string): Observable<IOrganisation> {
        return this.settingsSelectors.organisation$.pipe(
            tap(organisation => {
                if (!organisation || organisation?.slug !== slug) {
                    this.settingsDispatchers.loadOrganisation(slug);
                }
            }),
            filter(organisation => organisation?.slug === slug),
            tap((organisation: IOrganisation) => {
                this.permissionsService.flushPermissions();
                this.permissionsService.loadPermissions(organisation.user_permissions);
                this.settingsDispatchers.setCurrentOrganisation(organisation.id);
            }),
            take(1)
        );
    }
}
