import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation
} from '@angular/core';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { Observable, Subject } from 'rxjs';
import { TemplatePortal } from '@angular/cdk/portal';
import { debounceTime, filter, map } from 'rxjs/operators';
import { IUser } from '@models/user';
import { ITeam } from '@models/team';
import { ModelSelectorStore } from '@shared/components/model-selector/model-selector.store';
import { ICustomer } from '@models/customer';
import { IContact } from '@models/contact';
import { IAddress } from '@models/address';
import { ICourier } from '@models/courier';
import { IDevice } from '@models/device';
import { IPointOfInterestTarget } from '@models/point-of-interest';
import { IShipment } from '@models/shipment';
import { IOrder } from '@models/order';

// eslint-disable-next-line prettier/prettier
export const availableModels = [
    'User',
    'Team',
    'Courier',
    'Customer',
    'Contact',
    'Address',
    'Device',
    'POITarget',
    'Shipment',
    'Order'
] as const;
export type SelectorModel = (typeof availableModels)[number];
// eslint-disable-next-line prettier/prettier
export type SelectableModel =
    | IUser
    | ITeam
    | ICustomer
    | IContact
    | IAddress
    | ICourier
    | IDevice
    | IPointOfInterestTarget
    | IShipment
    | IOrder;

@Component({
    selector: 'model-selector',
    templateUrl: './model-selector.component.html',
    styleUrls: ['./model-selector.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [ModelSelectorStore]
})
export class AppModelSelectorComponent implements OnInit, OnDestroy, OnChanges {
    @Output() updateModel = new EventEmitter<SelectableModel>();
    @Input() model: SelectorModel = 'User';
    @Input() filter: any;
    @Input() searchPlaceholder: string = 'search...';
    @Input() viewSettings: Record<string, any> = {};
    @Input() params: Record<string, any> = {};

    @ViewChild('userSelectorOrigin') private _userSelectorOrigin: ElementRef;
    @ViewChild('userSelectorPanel') private _userSelectorPanel: TemplateRef<any>;

    userFilter = new FormControl('');

    loading$: Observable<boolean>;
    data$: Observable<SelectableModel[]>;
    page$: Observable<number>;
    lastPage$: Observable<number>;

    reloadRequired = false;

    private _overlayRef: OverlayRef;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(
        public router: Router,
        private _overlay: Overlay,
        private _viewContainerRef: ViewContainerRef,
        private readonly modelSelectorStore: ModelSelectorStore
    ) {
        this.loading$ = modelSelectorStore.loading$;
        this.data$ = modelSelectorStore.data$.pipe(
            filter(d => !!d),
            map(d => d.data)
        );
        this.page$ = modelSelectorStore.data$.pipe(map(d => (d ? d.current_page : 0)));
        this.lastPage$ = modelSelectorStore.data$.pipe(map(d => (d ? d.last_page : 0)));

        this.userFilter.valueChanges
            .pipe(debounceTime(1000))
            .subscribe(v =>
                this.modelSelectorStore.load({ search: v, filter: this.filter, overwrite: true, params: this.params })
            );
    }

    @HostListener('scroll', ['$event'])
    onScroll(event: any): void {
        // visible height + pixel scrolled >= total height
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 1) {
            this.modelSelectorStore.loadNextPage({ search: this.userFilter.value, filter: this.filter });
        }
    }

    ngOnInit(): void {
        this.modelSelectorStore.setModel(this.model);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['filter'] && !changes['filter'].isFirstChange()) {
            this.reloadRequired = true;
        }
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();

        // Dispose the overlay
        if (this._overlayRef) {
            this._overlayRef.dispose();
        }
    }

    submit(): void {}

    openPanel(): void {
        // Return if the messages panel or its origin is not defined
        if (!this._userSelectorPanel || !this._userSelectorOrigin) {
            return;
        }

        // Create the overlay if it doesn't exist
        if (!this._overlayRef) {
            this._createOverlay();
            this.modelSelectorStore.load({ search: '', filter: this.filter, overwrite: true, params: this.params });
        } else {
            if (this.reloadRequired) {
                this.modelSelectorStore.load({ search: '', filter: this.filter, overwrite: true, params: this.params });
            }
        }

        // Attach the portal to the overlay
        this._overlayRef.attach(new TemplatePortal(this._userSelectorPanel, this._viewContainerRef));
        this.init();
    }

    selectModel(model: SelectableModel): void {
        this.updateModel.emit(model);
        this.closePanel();
    }

    closePanel(): void {
        this._overlayRef.detach();
    }

    private _createOverlay(): void {
        // Create the overlay
        this._overlayRef = this._overlay.create({
            hasBackdrop: true,
            backdropClass: 'fuse-backdrop-on-mobile',
            positionStrategy: this._overlay
                .position()
                .flexibleConnectedTo(this._userSelectorOrigin.nativeElement)
                .withLockedPosition()
                .withPush(true)
                .withPositions([
                    {
                        originX: 'start',
                        originY: 'bottom',
                        overlayX: 'start',
                        overlayY: 'top'
                    },
                    {
                        originX: 'start',
                        originY: 'top',
                        overlayX: 'start',
                        overlayY: 'bottom'
                    },
                    {
                        originX: 'end',
                        originY: 'bottom',
                        overlayX: 'end',
                        overlayY: 'top'
                    },
                    {
                        originX: 'end',
                        originY: 'top',
                        overlayX: 'end',
                        overlayY: 'bottom'
                    }
                ])
        });

        // Detach the overlay from the portal on backdrop click
        this._overlayRef.backdropClick().subscribe(() => {
            this._overlayRef.detach();
        });
    }

    private init(): void {
        if (this.userFilter.value !== '') {
            this.userFilter.setValue('', { emitEvent: false });
            this.modelSelectorStore.load({ search: '', filter: this.filter, overwrite: true, params: this.params });
        }
    }
}
