import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'entrance-layout',
    templateUrl: './entrance.component.html',
    styleUrls: ['./entrance.component.scss']
})
export class EntranceComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
