import { createAction, props } from '@ngrx/store';
import { IPartner } from '@models/partner';
import { IOrganisation } from '@models/organisation';

export const togglePartner = createAction('[Settings] Toggle Partner', props<{ partner: IPartner }>());
export const toggleAllPartners = createAction('[Settings] Toggle All Partners');

export const setInitialSelectedPartners = createAction('[Settings] Set Initial Selected Partners');
export const applySelectedPartnersChanges = createAction('[Settings] Apply Selected Partners Changes');
export const applySelectedPartnersChangesSuccess = createAction('[Settings] Apply Selected Partners Changes Success');
export const applySelectedPartnersChangesFailure = createAction('[Settings] Apply Selected Partners Changes Failure');
export const refreshPartnerRelatedData = createAction('[Settings] Refresh partner related data');

export const setOrganisations = createAction(
    '[Settings] Set Organisations',
    props<{ organisations: IOrganisation[] }>()
);
export const setOrganisation = createAction('[Settings] Set Organisation', props<{ organisation: IOrganisation }>());

export const setCurrentOrganisation = createAction('[Settings] Set Current Organisation', props<{ id: number }>());

export const loadOrganisation = createAction('[Settings] Load Organisation', props<{ slug: string }>());
export const loadOrganisationSuccess = createAction(
    '[Settings] Load Organisation Success',
    props<{ organisation: IOrganisation }>()
);
export const loadOrganisationFailure = createAction('[Settings] Load Organisation Failure', props<{ error: any }>());
