import {
    ChangeDetectionStrategy,
    Component,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation
} from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { MatButton } from '@angular/material/button';
import { Observable, Subject } from 'rxjs';
import { WebsocketService } from '@core/services/services/websocket.service';
import { NotificationsStore } from './notifications.store';
import { INotification } from '@models/notification';
import { IPagination } from '@models/pagination';
import { SnackbarService } from '@core/services/services/snackbar.service';
import { SoundService } from '@core/services/services/sound.service';

@Component({
    selector: 'notifications',
    templateUrl: './notifications.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'notifications',
    providers: [NotificationsStore]
})
export class NotificationsComponent implements OnInit, OnDestroy {
    @ViewChild('notificationsOrigin') private _notificationsOrigin: MatButton;
    @ViewChild('notificationsPanel') private _notificationsPanel: TemplateRef<any>;
    //

    notifications$: Observable<IPagination<INotification>>;

    count$: Observable<number>;
    private _overlayRef: OverlayRef;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(
        private _overlay: Overlay,
        private _viewContainerRef: ViewContainerRef,
        private websocketService: WebsocketService,
        public notificationStore: NotificationsStore,
        private snackbarService: SnackbarService,
        private soundService: SoundService
    ) {
        this.notifications$ = notificationStore.notifications$;
        this.count$ = notificationStore.count$;
    }

    ngOnInit(): void {
        this.notificationStore.loadNotifications();
        this.websocketService
            .getPrivateChannel()
            .listen('.notification.created', event => {
                this.notificationStore.addNotification(event.model);
                this.snackbarService.show('info', event.model.data.message, event.model.data.title);
                this.soundService.notification();
            })
            .listen('.notification.deleted', () => {});
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();

        // Dispose the overlay
        if (this._overlayRef) {
            this._overlayRef.dispose();
        }
    }

    openPanel(): void {
        // Return if the messages panel or its origin is not defined
        if (!this._notificationsPanel || !this._notificationsOrigin) {
            return;
        }

        // Create the overlay if it doesn't exist
        if (!this._overlayRef) {
            this._createOverlay();
        }

        // Attach the portal to the overlay
        this._overlayRef.attach(new TemplatePortal(this._notificationsPanel, this._viewContainerRef));
    }

    getIcon(slug): string {
        switch (slug) {
            case 'system':
                return 'heroicons_outline:terminal';
            default:
                return 'heroicons_outline:bell';
        }
    }

    closePanel(): void {
        this._overlayRef.detach();
    }

    deleteAll(): void {
        this.notificationStore.deleteAllNotifications();
    }

    delete(notification: INotification): void {
        this.notificationStore.deleteNotification(notification.id);
    }

    private _createOverlay(): void {
        // Create the overlay
        this._overlayRef = this._overlay.create({
            hasBackdrop: true,
            backdropClass: 'fuse-backdrop-on-mobile',
            positionStrategy: this._overlay
                .position()
                .flexibleConnectedTo(this._notificationsOrigin._elementRef.nativeElement)
                .withLockedPosition()
                .withPush(true)
                .withPositions([
                    {
                        originX: 'start',
                        originY: 'bottom',
                        overlayX: 'start',
                        overlayY: 'top'
                    },
                    {
                        originX: 'start',
                        originY: 'top',
                        overlayX: 'start',
                        overlayY: 'bottom'
                    },
                    {
                        originX: 'end',
                        originY: 'bottom',
                        overlayX: 'end',
                        overlayY: 'top'
                    },
                    {
                        originX: 'end',
                        originY: 'top',
                        overlayX: 'end',
                        overlayY: 'bottom'
                    }
                ])
        });

        // Detach the overlay from the portal on backdrop click
        this._overlayRef.backdropClick().subscribe(() => {
            this._overlayRef.detach();
        });
    }
}
