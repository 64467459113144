import { Pipe, PipeTransform } from '@angular/core';
import { iconMapping } from '@shared/types/icon-mapping';

@Pipe({
    name: 'icon'
})
export class IconPipe implements PipeTransform {
    transform(value: string, ns: string, fallback: string = ''): string {
        return iconMapping[ns][value] ?? fallback;
    }
}
