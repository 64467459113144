import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { debounceTime, filter, map } from 'rxjs/operators';
import { IUser } from '@models/user';
import { ITeam } from '@models/team';
import { ModelSelectorStore } from '@shared/components/model-selector/model-selector.store';
import { ICustomer } from '@models/customer';
import { IContact } from '@models/contact';
import { IAddress } from '@models/address';
import { ICourier } from '@models/courier';
import { IDevice } from '@models/device';
import { MatSelectionList } from '@angular/material/list';

export const availableModels = [
    'User',
    'Team',
    'Courier',
    'Customer',
    'Contact',
    'Address',
    'Device',
    'Shipment'
] as const;
export type SelectorModel = (typeof availableModels)[number];
export type SelectableModel = (IUser | ITeam | ICustomer | IContact | IAddress | ICourier | IDevice) & {
    selected: boolean;
};

@Component({
    selector: 'model-selector-embedded',
    templateUrl: './model-selector-embedded.component.html',
    styleUrls: ['./model-selector-embedded.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [ModelSelectorStore]
})
export class ModelSelectorEmbeddedComponent implements OnDestroy, OnChanges, AfterViewInit {
    @Output() updateModel = new EventEmitter<SelectableModel>();
    @Output() selected = new EventEmitter<Array<SelectableModel>>();

    @Input() model: SelectorModel = 'User';
    @Input() filter: any;

    @ViewChild(MatSelectionList) selection: MatSelectionList;
    @ViewChild('user') user: TemplateRef<any>;
    @ViewChild('team') team: TemplateRef<any>;
    @ViewChild('courier') courier: TemplateRef<any>;
    @ViewChild('address') address: TemplateRef<any>;
    @ViewChild('customer') customer: TemplateRef<any>;
    @ViewChild('contact') contact: TemplateRef<any>;
    @ViewChild('device') device: TemplateRef<any>;

    @ViewChild('userSelectorOrigin') private _userSelectorOrigin: ElementRef;
    @ViewChild('userSelectorPanel') private _userSelectorPanel: TemplateRef<any>;

    readonly selectedModels = new Map<number, SelectableModel>();

    template: TemplateRef<any>;

    userFilter = new FormControl('');

    loading$: Observable<boolean>;
    data$: Observable<SelectableModel[]>;
    page$: Observable<number>;
    lastPage$: Observable<number>;

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(public router: Router, private readonly modelSelectorStore: ModelSelectorStore) {
        this.loading$ = modelSelectorStore.loading$;
        this.data$ = modelSelectorStore.data$.pipe(
            filter(d => !!d),
            map(d => d.data),
            map(items =>
                items.map((item: SelectableModel) => {
                    item.selected = this.selectedModels.has(item.id);

                    return item;
                })
            )
        );
        this.page$ = modelSelectorStore.data$.pipe(map(d => (d ? d.current_page : 0)));
        this.lastPage$ = modelSelectorStore.data$.pipe(map(d => (d ? d.last_page : 0)));

        this.userFilter.valueChanges
            .pipe(debounceTime(1000))
            .subscribe(v => this.modelSelectorStore.load({ search: v, filter: this.filter, overwrite: true }));
    }

    @HostListener('scroll', ['$event'])
    onScroll(event: any): void {
        // visible height + pixel scrolled >= total height
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 1) {
            this.modelSelectorStore.loadNextPage({ search: this.userFilter.value, filter: this.filter });
        }
    }

    ngAfterViewInit(): void {
        this.template = this[this.model.toLocaleLowerCase()];
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.modelSelectorStore.setModel(this.model);

        if (changes['filter']) {
            this.modelSelectorStore.load({ search: '', filter: this.filter, overwrite: true });
        }
    }

    onOptionChanged(item: SelectableModel, selected: boolean): void {
        if (selected) {
            this.selectedModels.set(item.id, item);
        } else {
            this.selectedModels.delete(item.id);
        }

        this.selected.emit([...this.selectedModels.values()]);
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    closePanel(): void {}
}
