import { createSelector, Store } from '@ngrx/store';
import { IAppCore } from './app-core.reducer';
import { AppState } from '../reducers';
import { Injectable } from '@angular/core';

export const getAppCoreState = (state: AppState): IAppCore => state.core;

export const getStyle = createSelector(getAppCoreState, (state: IAppCore) => state.style);
export const getColorTheme = createSelector(getAppCoreState, (state: IAppCore) => state.colorTheme);
export const getScheme = createSelector(getAppCoreState, (state: IAppCore) => state.scheme);

export const getNavbar = createSelector(getAppCoreState, (state: IAppCore) => state.navbar);
export const getNavbarPrimaryBackground = createSelector(
    getAppCoreState,
    (state: IAppCore) => state.navbar.primaryBackground
);
export const getNavbarSecondaryBackground = createSelector(
    getAppCoreState,
    (state: IAppCore) => state.navbar.secondaryBackground
);
export const getNavbarFolded = createSelector(getAppCoreState, (state: IAppCore) => state.navbar.folded);
export const getNavbarHidden = createSelector(getAppCoreState, (state: IAppCore) => state.navbar.hidden);

export const getToolbar = createSelector(getAppCoreState, (state: IAppCore) => state.toolbar);
export const getToolbarBackground = createSelector(getAppCoreState, (state: IAppCore) => state.toolbar.background);
export const getToolbarHidden = createSelector(getAppCoreState, (state: IAppCore) => state.toolbar.hidden);

export const getMetainfo = createSelector(getAppCoreState, (state: IAppCore) => state.metainfo);
export const getSubject = createSelector(getAppCoreState, (state: IAppCore) => state.metainfo.subject);
export const getIcon = createSelector(getAppCoreState, (state: IAppCore) => state.metainfo.icon);
export const getLogo = createSelector(getAppCoreState, (state: IAppCore) => state.metainfo.logo);
export const getType = createSelector(getAppCoreState, (state: IAppCore) => state.metainfo.type);
export const getSlug = createSelector(getAppCoreState, (state: IAppCore) => state.metainfo.slug);
export const getBaseUrl = createSelector(getAppCoreState, (state: IAppCore) =>
    state.metainfo.type && state.metainfo.slug ? `/${state.metainfo.type}/${state.metainfo.slug}` : ''
);

export const getSidepanelHidden = createSelector(getAppCoreState, (state: IAppCore) => state.sidepanel.hidden);

export const mentions = createSelector(getAppCoreState, (state: IAppCore) => state.metainfo.mentions);

export const getTitleVars = createSelector(getAppCoreState, (state: IAppCore) => state.titleVars);

@Injectable()
export class AppCoreSelectors {
    // selectors$
    style$ = this.store.select(getStyle);
    colorTheme$ = this.store.select(getColorTheme);
    scheme$ = this.store.select(getScheme);

    // Navbar selectors
    navbar$ = this.store.select(getNavbar);
    navbarPrimaryBackground$ = this.store.select(getNavbarPrimaryBackground);
    navbarSecondaryBackground$ = this.store.select(getNavbarSecondaryBackground);
    navbarFolded$ = this.store.select(getNavbarFolded);
    navbarHidden$ = this.store.select(getNavbarHidden);

    // Toolbar
    toolbar$ = this.store.select(getToolbar);
    toolbarBackground$ = this.store.select(getToolbarBackground);
    toolbarHidden$ = this.store.select(getToolbarHidden);

    // Metainfo
    metainfo$ = this.store.select(getMetainfo);
    subject$ = this.store.select(getSubject);
    icon$ = this.store.select(getIcon);
    logo$ = this.store.select(getLogo);
    type$ = this.store.select(getType);
    slug$ = this.store.select(getSlug);
    baseUrl$ = this.store.select(getBaseUrl);

    // Sidepanel
    sidepanelHidden$ = this.store.select(getSidepanelHidden);

    mentions$ = this.store.select(mentions);
    titleVars = this.store.select(getTitleVars);
    constructor(private store: Store<AppState>) {}
}
