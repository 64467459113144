<div class="flex flex-col sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-auto min-w-0">
    <div class="relative hidden md:flex flex-auto items-center justify-center h-full p-12 lg:px-28 overflow-hidden dark:border-r">
        <video class="absolute z--1 w-auto min-w-full min-h-full max-w-none" autoplay loop playsinline [muted]="'muted'">
            <source src="/assets/video/intovideo.webm" type="video/webm" />
            Your browser does not support the video tag.
        </video>
        <div class="absolute z-10 bg-gray-800/10 w-auto min-w-full min-h-full"></div>
        <!--        <div class="z-10 mt-auto relative w-full">-->
        <!--            <div class="text-8xl leading-none text-gray-100 font-medium text-center tracking-widest">-->
        <!--                <div>Those days of lack</div>-->
        <!--                <div>of visibility are over</div>-->
        <!--            </div>-->
        <!--        </div>-->
    </div>

    <div class="md:flex md:justify-end sm:w-auto md:h-full sm:rounded-2xl md:rounded-none sm:shadow md:shadow-none sm:bg-card">
        <router-outlet *ngIf="true"></router-outlet>
    </div>
</div>
