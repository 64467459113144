import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { ModalDialogComponent } from '@shared/components/modal-dialog/modal-dialog.component';
import { ModalDialogService } from '@shared/components/modal-dialog/modal-dialog.service';

@NgModule({
    imports: [SharedModule],
    declarations: [ModalDialogComponent],
    providers: [ModalDialogService],
    exports: [ModalDialogComponent]
})
export class ModalDialogModule {}
