import { Injectable } from '@angular/core';
import { ApiHttpService } from '@core/services/services/api-http.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResourceService } from '@core/services/resource/resource.service';
import { IPagination } from '@models/pagination';
import { QueryStringParameters } from '@core/services/services/query-string-parameters';
import { ITeam } from '@models/team';

@Injectable({
    providedIn: 'root'
})
export class TeamsService extends ResourceService {
    constructor(protected httpService: ApiHttpService) {
        super();
    }

    loadTeams(params: { [key: string]: any } = {}): Observable<ITeam[] | IPagination<ITeam>> {
        return this.httpService
            .get<any>(
                this.createUrlWithQueryParameters('teams', (qs: QueryStringParameters) => {
                    for (const key of Object.keys(params)) {
                        qs.push(key, params[key]);
                    }
                })
            )
            .pipe(map((response: any) => response.data));
    }
}
