import { Component, Input, OnChanges } from '@angular/core';
import { IDevice } from '@models/device';

@Component({
    selector: 'app-device-preview-geolocation',
    template: `
        <div class="flex flex-col items-center" *ngIf="!device.lat || !device.lng; else withGeoposition">
            <mat-icon svgIcon="logivice:location" class="text-gray-500"></mat-icon>
        </div>
        <ng-template #withGeoposition>
            <map-dialog [point]="{ lat: device.lat, lng: device.lng }">
                <div class="flex flex-col items-center max-w-full w-full" [matTooltip]="tooltip">
                    <mat-icon svgIcon="logivice:location" class="text-green-600"></mat-icon>
                    <span class="text-xs" *ngIf="expanded">{{ device.address?.city }}</span>
                    <span class="text-xs" *ngIf="expanded">
                        {{ device.address?.state }}, {{ device.address?.countryName }}
                    </span>
                </div>
            </map-dialog>
        </ng-template>
    `
})
export class DevicePreviewGeolocationComponent implements OnChanges {
    @Input() device: IDevice;
    @Input() expanded = true;
    tooltip = null;

    ngOnChanges(): void {
        if (!this.expanded) {
            this.tooltip = this.device.address?.label;
        }
    }
}
