import colors from 'tailwindcss/colors';
import { IMapCoordinates } from '@shared/components/leaflet-map/map-configuration';

export const getConfigNames = (item: {
    configurations?: string;
    related_configurations?: { name: string }[];
}): string => {
    if (!item) {
        return '';
    }
    if (item.configurations) {
        return item.configurations;
    } else if (item.related_configurations && Array.isArray(item.related_configurations)) {
        return item.related_configurations.map((conf: { name: string }) => conf.name).join(', ');
    }
    return '';
};

export const similarity = (s1, s2): number => {
    let longer = s1;
    let shorter = s2;
    if (s1.length < s2.length) {
        longer = s2;
        shorter = s1;
    }
    const longerLength = longer.length;
    if (longerLength === 0) {
        return 1.0;
    }
    return ((longerLength - editDistance(longer, shorter)) / parseFloat(longerLength)) * 100;
};

const editDistance = (s1, s2): number => {
    s1 = s1.toLowerCase();
    s2 = s2.toLowerCase();

    const costs = [];
    for (let i = 0; i <= s1.length; i++) {
        let lastValue = i;
        for (let j = 0; j <= s2.length; j++) {
            if (i === 0) {
                costs[j] = j;
            } else {
                if (j > 0) {
                    let newValue = costs[j - 1];
                    if (s1.charAt(i - 1) !== s2.charAt(j - 1)) {
                        newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
                    }
                    costs[j - 1] = lastValue;
                    lastValue = newValue;
                }
            }
        }
        if (i > 0) {
            costs[s2.length] = lastValue;
        }
    }
    return costs[s2.length];
};

export const snakeToCamel = (str: string): string => str.replace(/([-_]\w)/g, g => g[1].toUpperCase());

export const snakeToPascal = (str: string): string => {
    const camelCase = snakeToCamel(str);
    return camelCase[0].toUpperCase() + camelCase.substr(1);
};

export const snakeToTitleCase = (s): string =>
    s.replace(/^[-_]*(.)/, (_, c) => c.toUpperCase()).replace(/[-_]+(.)/g, (_, c) => ' ' + c.toUpperCase());

export type TimeParts = { h: number; m: number; s: number };

export const convertHMS = (sec: number): any => {
    const hours = Math.floor(sec / 3600);
    const minutes = Math.floor((sec - hours * 3600) / 60);
    const seconds = sec - hours * 3600 - minutes * 60;
    return [hours, minutes, seconds];
};

export const convertHMSAsObject = (sec: number): TimeParts => {
    const [h, m, s] = convertHMS(sec);

    return { h, m, s };
};

export const replaceTemplate = (template: string, data: any): string =>
    template.replace(/\[([^\]]*)\]/g, (_, key) => data[key] || `[${key}]`);

const addToObj = (obj_, path, newData): any => {
    const obj = typeof obj_ === 'string' ? {} : obj_; // Special logic to cause a value at 1.2.3. to override a value at 1.2.
    if (path.length === 0) {
        return newData;
    }
    const [head, ...tail] = path;
    return {
        ...obj,
        [head]: addToObj(obj[head] || {}, tail, newData)
    };
};

export const nestedToAssoc = (nested: any[]): any[] =>
    nested.reduce((obj, path) => addToObj(obj, path.split('.'), path), {});

export const hex2rgba = (hex, alpha = 1): string => {
    const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
    return `rgba(${r},${g},${b},${alpha})`;
};

export const getColorFromTailwind = (tailwindColor: string): any => {
    const [color, weight] = tailwindColor.split('-', 2);

    return colors[color][weight] ?? '#ffffff';
};

export function calculateDistance(lat1: number, lon1: number, lat2: number, lon2: number): number {
    const R = 6371; // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in km
}

export function deg2rad(deg: number): number {
    return deg * (Math.PI / 180);
}

export function areCoordinatesEqual(point1: IMapCoordinates, point2: IMapCoordinates): boolean {
    return point1.lat === point2.lat && point1.lng === point2.lng;
}

export function getUniqueLocations<T>(points: Array<T & IMapCoordinates>): Array<T & IMapCoordinates> {
    const lastItemIndex = points.length - 1;

    return points.filter((point: T & IMapCoordinates, index: number) => {
        return index < lastItemIndex ? !areCoordinatesEqual(point, points[index + 1]) : true;
    });
}

export type PathSegment<T> = { points: T[]; distanceInKm: number };

/**
 * SplitPathIntoSegments: the new segment will start from the point where the previous segment ended
 * example: 1 2 3 | 3 4 | 4 5 6 7
 */
export function splitPathIntoSegments<T extends IMapCoordinates>(
    points: T[],
    splitDistanceInKm: number
): PathSegment<T>[] {
    const segments = [];
    let segmentPoints = [points[0]];
    let segmentDistanceInKm = 0;

    for (let i = 1; i < points.length; i++) {
        const [prevPoint, point] = [points[i - 1], points[i]];

        const distanceBetweenPointsInKm = calculateDistance(prevPoint.lat, prevPoint.lng, point.lat, point.lng);

        if (distanceBetweenPointsInKm >= splitDistanceInKm) {
            if (segmentPoints.length > 1) {
                segments.push({
                    points: segmentPoints,
                    distanceInKm: segmentDistanceInKm
                });
            }

            segments.push({
                points: [prevPoint, point],
                distanceInKm: distanceBetweenPointsInKm
            });

            segmentPoints = [point];
            segmentDistanceInKm = 0;
            continue;
        }

        segmentPoints.push(point);
        segmentDistanceInKm += distanceBetweenPointsInKm;
    }

    if (segmentPoints.length > 0) {
        segments.push({
            points: segmentPoints,
            distanceInKm: segmentDistanceInKm
        });
    }

    return segments;
}
