<div class="relative">
    <div class="relative min-h-100 flex justify-center">
        <div class="cube-container">
            <button mat-button (click)="rotateUp()" class="mb-20"><mat-icon svgIcon="heroicons_solid:chevron-up"></mat-icon></button>
            <div id="cube" class="cube" [ngStyle]="{ transform }">
                <div class="cube-side"><span class="pl-18">left</span></div>
                <div class="cube-side">front</div>
                <div class="cube-side"><span class="pl-18">right</span></div>
                <div class="cube-side">back</div>
                <div class="cube-side" data-side="bottom"></div>
                <div class="cube-side" data-side="top"></div>
            </div>
        </div>
    </div>

    <button mat-button (click)="rotateLeft()" class="absolute left-10 top-40"><mat-icon svgIcon="heroicons_solid:chevron-left"></mat-icon></button>
    <button mat-button (click)="rotateRight()" class="absolute right-10 top-40"><mat-icon svgIcon="heroicons_solid:chevron-right"></mat-icon></button>
    <button mat-button (click)="rotateDown()" class="absolute left-1/2 -ml-8 top-100"><mat-icon svgIcon="heroicons_solid:chevron-down"></mat-icon></button>
</div>

<div class="flex mt-18">
    <div class="flex-1">
        <div class="max-w-72 ml-20 flex flex-col items-center">
            <img src="/assets/images/other/accelerometer.svg" alt="accelerometer" class="object-contain max-w-48" />
            <span class="text-sm text-secondary">The accelerometer is a sensor that measures the acceleration of an object, detecting changes in its motion or orientation in space.</span>
        </div>
    </div>
    <div class="flex-1 flex flex-col pt-2 pl-40">
        <span class="font-bold text-slate-500 mb-4">Vector</span>
        <span class="flex">
            <span class="w-20">
                <b>x:</b>
                {{ position[0] }}
            </span>
            <span class="w-20">
                <b>y:</b>
                {{ position[1] }}
            </span>
            <span class="w-20">
                <b>z:</b>
                {{ position[2] }}
            </span>
        </span>

        <span class="font-bold text-slate-500 mt-5 mb-4">Pitch angles</span>
        <span class="mb-4 flex flex-col" *ngIf="orientation">
            <div class="flex">
                <span class="font-bold w-16">Pitch X:</span>
                <span>{{ orientation.pitchX | number : "1.0-2" }} degrees</span>
            </div>
            <div class="flex">
                <span class="font-bold w-16">Pitch Y:</span>
                <span>{{ orientation.pitchY | number : "1.0-2" }} degrees</span>
            </div>
            <div class="flex">
                <span class="font-bold w-16">Pitch Z:</span>
                <span>{{ orientation.pitchZ | number : "1.0-2" }} degrees</span>
            </div>
            <div *ngIf="flipped">Box flipped down!</div>
        </span>
    </div>
</div>
