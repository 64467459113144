import { Injectable } from '@angular/core';
import { ApiHttpService } from '@core/services/services/api-http.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResourceService } from '@core/services/resource/resource.service';
import { IPagination } from '@models/pagination';
import { QueryStringParameters } from '@core/services/services/query-string-parameters';
import { ICourier } from '@models/courier';

@Injectable({
    providedIn: 'root'
})
export class CouriersService extends ResourceService {
    constructor(protected httpService: ApiHttpService) {
        super();
    }

    loadCouriers(params: { [key: string]: any } = {}): Observable<ICourier[] | IPagination<ICourier>> {
        return this.httpService
            .get<any>(
                this.createUrlWithQueryParameters('couriers', (qs: QueryStringParameters) => {
                    for (const key of Object.keys(params)) {
                        qs.push(key, params[key]);
                    }
                })
            )
            .pipe(map((response: any) => response.data));
    }
}
