import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'snakeToTitleCase'
})
export class TitlecasePipe implements PipeTransform {
    public transform(value: string): string {
        const sentence = value.toLowerCase().split('_');
        for (let i = 0; i < sentence.length; i++) {
            sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
        }

        return sentence.join(' ');
    }
}
