import { ComponentStore } from '@ngrx/component-store';
import { Injectable } from '@angular/core';
import { switchMap, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { IPagination } from '@models/pagination';
import { TaskService } from '@core/services/resource/task.service';
import { IBackgroundTask } from '@models/background-task';

export interface TasksState {
    tasks?: IPagination<IBackgroundTask>;
    count: number;
    processing: boolean;
}

@Injectable()
export class TasksStore extends ComponentStore<TasksState> {
    readonly tasks$ = this.select(state => state.tasks);
    readonly count$ = this.select(state => state.count);
    readonly processing$ = this.select(state => state.processing);

    readonly setProcessing = this.updater(
        (state: TasksState, processing: boolean) => ({
            ...state,
            processing
        })
    );

    readonly updateTasks = this.updater(
        (state: TasksState, tasks: IPagination<IBackgroundTask>) => ({
            ...state,
            tasks,
            count: tasks.total
        })
    );

    readonly increaseCount = this.updater((state: TasksState) => ({
        ...state,
        count: state.count + 1
    }));

    readonly decreaseCount = this.updater((state: TasksState) => ({
        ...state,
        count: state.count - 1
    }));

    readonly addTask = this.updater((state: TasksState, task: IBackgroundTask) => ({
        ...state,
        tasks: { ...state.tasks, data: [task, ...state.tasks.data] },
        count: state.count + 1
    }));

    readonly updateTask = this.updater(
        (state: TasksState, task: IBackgroundTask) => {
            const index = state.tasks.data.findIndex(t => t.id === task.id);
            if (index === -1) {
                this.addTask(task);
                return state;
            }

            state.tasks.data[index] = task;
            return { ...state, tasks: { ...state.tasks } };
        }
    );

    readonly loadTasks = this.effect<void>(trigger$ =>
        trigger$.pipe(
            tap(() => this.setProcessing(true)),

            switchMap(() =>
                this.taskService.loadTasks().pipe(
                    tap({
                        next: tasks => this.updateTasks(tasks)
                    })
                )
            ),
            tap(() => this.setProcessing(false))
        )
    );

    readonly deleteTask = this.effect((id$: Observable<number>) =>
        id$.pipe(
            tap(() => this.setProcessing(true)),
            switchMap(id =>
                this.taskService
                    .deleteTask(id)
                    .pipe(tap({ next: task => this.loadTasks() }))
            ),
            tap(() => this.setProcessing(false))
        )
    );

    readonly deleteAllTasks = this.effect<void>(trigger$ =>
        trigger$.pipe(
            tap(() => this.setProcessing(true)),
            switchMap(() =>
                this.taskService
                    .deleteAllTasks()
                    .pipe(tap({ next: data => this.loadTasks() }))
            ),
            tap(() => this.setProcessing(false))
        )
    );

    constructor(private taskService: TaskService) {
        super({ tasks: undefined, count: 0, processing: false });
    }
}
