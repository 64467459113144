<div class="md:flex md:justify-end w-full sm:w-auto md:h-full py-8 px-4 sm:p-8 md:p-12 md:pt-24 sm:rounded-2xl md:rounded-none sm:shadow md:shadow-none sm:bg-card">
    <div class="w-full mx-auto sm:mx-0">
        <!-- Logo -->
        <div class="w-full">
            <img src="assets/images/logo/logo-text.png" class="object-scale-down h-48 w-full" />
        </div>

        <div class="flex flex-col flex-auto sm:max-h-120 space-y-1 overflow-y-auto py-1" *ngrxLet="organisations$; let organisations">
            <div class="flex items-center justify-between cursor-pointer bg-gray-100 rounded-md hover:bg-gray-300 px-2 py-1" *ngFor="let o of organisations" (click)="selectOrganisation(o)">
                <div class="flex items-center">
                    <img [src]="o.icon" [alt]="o.slug" class="h-full w-10 h-10 object-contain mr-2" />
                    <span class="text-xl uppercase font-semibold">{{ o.name }}</span>
                </div>
                <mat-icon svgIcon="mat_outline:chevron_right"></mat-icon>
            </div>
        </div>
    </div>
</div>
