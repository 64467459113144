import { Observable, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { UrlBuilder } from '@core/services/services/url-builder';
import { QueryStringParameters } from '@core/services/services/query-string-parameters';
import { environment } from '@env/environment';

export type QueryParams = { [key: string]: any };

export class ResourceService {
    public readonly API_PREFIX: string = '/api/v1';

    constructor() {}

    private get apiEndpoint(): string {
        return environment.apiEndpoint + this.API_PREFIX;
    }

    protected handleError<T>(): (res: HttpErrorResponse) => Observable<never> {
        return (res: HttpErrorResponse) => throwError(res.error.message || 'Something went wrong');
    }

    // URL
    protected createUrl(action: string, queryParams?: QueryParams): string {
        const urlBuilder: UrlBuilder = new UrlBuilder(this.apiEndpoint, action);

        if (queryParams) {
            for (const key of Object.keys(queryParams)) {
                urlBuilder.queryString.push(key, queryParams[key]);
            }
        }

        return urlBuilder.toString();
    }

    // URL WITH QUERY PARAMS
    protected createUrlWithQueryParameters(
        action: string,
        queryStringHandler?: (queryStringParameters: QueryStringParameters) => void
    ): string {
        const urlBuilder: UrlBuilder = new UrlBuilder(this.apiEndpoint, action);
        if (queryStringHandler) {
            queryStringHandler(urlBuilder.queryString);
        }
        return urlBuilder.toString();
    }

    // URL WITH PATH VARIABLES
    protected createUrlWithPathVariables(action: string, pathVariables: any[] = []): string {
        let encodedPathVariablesUrl = '';
        // Push extra path variables
        for (const pathVariable of pathVariables) {
            if (pathVariable !== null) {
                encodedPathVariablesUrl += `/${encodeURIComponent(pathVariable.toString())}`;
            }
        }
        const urlBuilder: UrlBuilder = new UrlBuilder(this.apiEndpoint, `${action}${encodedPathVariablesUrl}`);
        return urlBuilder.toString();
    }
}
