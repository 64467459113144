import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'timezone'
})
export class TimezoneDirective implements PipeTransform {
    transform(value: any, timezone?: string): string {
        return timezone ? new Date(value).toLocaleString('en-US', { timeZone: timezone }) : value;
    }
}
