import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { DevicePreviewComponent } from './device-preview.component';
import { DevicePreviewActivityComponent } from './pieces/activity.component';
import { DevicePreviewGeolocationComponent } from './pieces/geolocation.component';
import { DevicePreviewOpeningComponent } from './pieces/opening.component';
import { DevicePreviewShockComponent } from './pieces/shock.component';
import { DevicePreviewTemperatureComponent } from './pieces/temperature.component';
import { DevicePreviewBatteryComponent } from './pieces/battery.component';
import { MapDialogModule } from '@shared/components/map-dialog/map-dialog.module';
import { DeviceTagsComponent } from './tags/device-tags.component';

@NgModule({
    imports: [SharedModule, MapDialogModule],
    providers: [],
    declarations: [
        DevicePreviewComponent,
        DevicePreviewActivityComponent,
        DevicePreviewGeolocationComponent,
        DevicePreviewOpeningComponent,
        DevicePreviewShockComponent,
        DevicePreviewTemperatureComponent,
        DevicePreviewBatteryComponent,
        DeviceTagsComponent
    ],
    exports: [
        DevicePreviewComponent,
        DevicePreviewActivityComponent,
        DevicePreviewGeolocationComponent,
        DevicePreviewOpeningComponent,
        DevicePreviewShockComponent,
        DevicePreviewTemperatureComponent,
        DevicePreviewBatteryComponent,
        DeviceTagsComponent
    ]
})
export class DevicePreviewModule {}
