import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IndividualConfig, ToastContainerDirective, ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class SnackbarService implements OnDestroy {
    private readonly ngDestroy = new Subject();

    constructor(private matSnackBar: MatSnackBar, private toastrService: ToastrService) {}

    public ngOnDestroy(): void {
        this.ngDestroy.next(null);
        this.ngDestroy.complete();
    }

    public show(
        type: 'success' | 'error' | 'info' | 'warning',
        message: string | Array<string> | null,
        title?: string,
        config?: Partial<IndividualConfig>
    ): void {
        config = { ...config, closeButton: true, enableHtml: true };

        if (this.toastrService.overlayContainer) {
            config = { ...config, positionClass: 'inline-toast-top-right' };
        }

        title = title || type[0].toUpperCase() + type.slice(1);
        if (typeof message === 'string') {
            this.toastrService[type](message, title, config);
        } else {
            if (message) {
                message.forEach(messageText => {
                    this.toastrService[type](messageText, title, config);
                });
            }
        }
    }

    public setContainer(container: ToastContainerDirective): void {
        this.toastrService.overlayContainer = container;
    }

    public removeContainer(): void {
        this.toastrService.overlayContainer = null;
    }
}
