<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full">
    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>

        <mat-sidenav [style.width.px]="sidebarService.width" [@.disabled]="true">
            <ng-template #sidebarContent></ng-template>
        </mat-sidenav>
    </div>
</div>
