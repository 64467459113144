import { createSelector, Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { ISettingsState } from './settings.reducer';
import { AppState } from '@store/reducers';

export const getSettingsState = (state: AppState): ISettingsState => state.settings;

export const getCheckedPartners = createSelector(getSettingsState, (state: ISettingsState) =>
    state.organisation.allowed_partners.map(p => ({ ...p, is_selected: state.selectedPartnersIds.indexOf(p.id) > -1 }))
);

export const getOrganisation = createSelector(getSettingsState, (state: ISettingsState) => state.organisation);

export const organisationLoading = createSelector(getSettingsState, (state: ISettingsState) => state.loading);

export const getAllowedPartners = createSelector(
    getSettingsState,
    (state: ISettingsState) => state.organisation?.allowed_partners || []
);

export const getSelectedPartners = createSelector(getAllowedPartners, partners => partners.filter(p => p.is_selected));

export const getAllowedThreepls = createSelector(
    getSettingsState,
    (state: ISettingsState) => state.organisation?.allowed_threepls || []
);

export const getSelectedPartnersIds = createSelector(
    getSettingsState,
    (state: ISettingsState) => state.selectedPartnersIds
);

export const getSubmittingSelected = createSelector(
    getSettingsState,
    (state: ISettingsState) => state.submittingSelected
);

export const getActiveIntegrations = createSelector(getSettingsState, (state: ISettingsState) =>
    state.organisation.connections.filter(connection => connection.status === 'active').map(c => c.connectable_type)
);

export const getAllIntegrations = createSelector(
    getSettingsState,
    (state: ISettingsState) => state.organisation.connections
);

@Injectable({
    providedIn: 'root'
})
export class SettingsSelectors {
    // selectors$
    checkedPartners$ = this.store.select(getCheckedPartners);
    selectedPartners$ = this.store.select(getSelectedPartners);

    submittingSelected$ = this.store.select(getSubmittingSelected);

    organisation$ = this.store.select(getOrganisation);
    allowedPartners$ = this.store.select(getAllowedPartners);
    allowedThreepls$ = this.store.select(getAllowedThreepls);
    selectedPartnersIds$ = this.store.select(getSelectedPartnersIds);

    activeIntegrations$ = this.store.select(getActiveIntegrations);
    allIntegrations$ = this.store.select(getAllIntegrations);

    // organisationLoading$ = this.store.select(getSelectedPartnersIds);

    constructor(private store: Store<AppState>) {}
}
