import { Injectable } from '@angular/core';
import { ApiHttpService } from '@core/services/services/api-http.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResourceService } from '@core/services/resource/resource.service';

@Injectable({
    providedIn: 'root'
})
export class FileService extends ResourceService {
    constructor(protected httpService: ApiHttpService) {
        super();
    }

    uploadFile(file: File, type: 'private' | 'public' = 'private'): Observable<any> {
        const formData = new FormData();
        formData.append('file', file, file.name);
        formData.append('type', type);
        return this.httpService
            .post(this.createUrl('files/upload'), formData)
            .pipe(map((response: any) => response.data));
    }

    uploadShort(file: File): Observable<any> {
        const formData = new FormData();
        formData.append('file', file, file.name);
        return this.httpService.post(this.createUrl('files'), formData).pipe(map((response: any) => response.data));
    }

    downloadFile(fileUrl: string): Observable<any> {
        return this.httpService
            .get<any>(fileUrl, { observe: 'response', responseType: 'blob' as 'json' })
            .pipe(map((response: any) => response));
    }
}
