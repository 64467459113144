import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LetDirective, PushPipe } from '@ngrx/component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { AppMapDialogComponent } from '@shared/components/map-dialog/map-dialog.component';
import { LeafletMapModule } from '@shared/components/leaflet-map/leaflet-map.module';

@NgModule({
    declarations: [AppMapDialogComponent],
    imports: [
        CommonModule,
        LetDirective,
        PushPipe,
        ReactiveFormsModule,
        MatInputModule,
        MatTooltipModule,
        MatIconModule,
        LeafletMapModule
    ],
    exports: [AppMapDialogComponent]
})
export class MapDialogModule {}
