import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LetDirective, PushPipe } from '@ngrx/component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { AppModelSelectorComponent } from '@shared/components/model-selector/model-selector.component';
import { DevicePreviewModule } from '../../../modules/ucontrol/devices/preview/device-preview.module';
import { MatButtonModule } from '@angular/material/button';
import { ModelSelectorEmbeddedComponent } from './model-selector-embedded/model-selector-embedded.component';
import { MatListModule } from '@angular/material/list';

@NgModule({
    declarations: [AppModelSelectorComponent, ModelSelectorEmbeddedComponent],
    imports: [
        CommonModule,
        LetDirective,
        PushPipe,
        ReactiveFormsModule,
        MatInputModule,
        MatTooltipModule,
        MatIconModule,
        DevicePreviewModule,
        MatButtonModule,
        MatListModule
    ],
    exports: [AppModelSelectorComponent, ModelSelectorEmbeddedComponent]
})
export class ModelSelectorModule {}
