import { Injectable, OnDestroy } from '@angular/core';

import { environment } from '@env/environment';
import Echo from 'laravel-echo';
import { Observable, Subject } from 'rxjs';
import { AuthSelectors } from '@store/auth';
import { filter, takeUntil } from 'rxjs/operators';
import { Channel } from 'laravel-echo/dist/channel';
import { User } from '@models/user';
import { AuthService } from '@core/services/resource/auth.service';
import { ApiHttpService } from '@core/services/services/api-http.service';

// @ts-ignore

@Injectable({
    providedIn: 'root'
})
export class WebsocketService implements OnDestroy {
    client: Echo;
    user$: Observable<User | null>; // Need to describe type after fix of effects
    auth$: Observable<string | null>; // Need to describe type after fix of effects
    userChannel: string;
    auth: string | null;
    private _unsubscribeAll: Subject<any>;

    constructor(
        private authSelectors: AuthSelectors,
        private authService: AuthService,
        protected httpService: ApiHttpService
    ) {
        this.user$ = authSelectors.user$;
        this.auth$ = authSelectors.auth$;

        /*TODO: Need to pass sanctum token for broadcast/auth authorisation*/
        this.client = new Echo({
            broadcaster: 'pusher',
            key: environment.pusher.options.key,
            wsHost: environment.pusher.options.host,
            wsPort: environment.pusher.options.port,
            wssPort: environment.pusher.options.port,
            forceTLS: environment.pusher.options.port === 443,
            encrypted: true,
            disableStats: true,
            enabledTransports: ['ws', 'wss'],
            authorizer: (channel, options): any => ({
                authorize: (socketId, callback): any => {
                    this.authService
                        .authorizePrivateBroadcast({
                            socket_id: socketId,
                            channel_name: channel.name
                        })
                        .subscribe(
                            response => callback(null, response),
                            error => callback(error)
                        );
                }
            })
        });

        this._unsubscribeAll = new Subject();
        this.user$
            .pipe(
                filter(u => !!u),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(user => {
                this.userChannel = 'users.' + user?.id;
            });
    }

    listenForUserNotifications<Model>(callback: (data: Model) => void): Channel {
        /*Need to change to private channel*/
        return this.client.private(this.userChannel).notification(callback);
    }

    listenForEvent<Model>(event, callback: (data: Model) => void): Channel {
        /*Need to change to private channel*/
        return this.client.private(this.userChannel).listen('.' + event, callback);
    }

    getPrivateChannel(channel?): Channel {
        return this.client.private(channel || this.userChannel);
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}
