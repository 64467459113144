import { Injectable } from '@angular/core';
import { ApiHttpService } from '@core/services/services/api-http.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResourceService } from '@core/services/resource/resource.service';
import { QueryStringParameters } from '@core/services/services/query-string-parameters';
import { IPagination } from '@models/pagination';
import { IKind, IMessage, IRequest, ITask } from '@models/request';

@Injectable({
    providedIn: 'root'
})
export class RequestService extends ResourceService {
    constructor(protected httpService: ApiHttpService) {
        super();
    }

    loadRequests(
        params: { [key: string]: any } = {},
        filters: { param: string; operator: string; value: any }[] = []
    ): Observable<IPagination<IRequest>> {
        return this.httpService
            .get<any>(
                this.createUrlWithQueryParameters('requests', (qs: QueryStringParameters) => {
                    for (const key of Object.keys(params)) {
                        qs.push(key, params[key]);
                    }

                    for (const filter of filters) {
                        qs.push(filter.param, filter.value, filter.operator);
                    }
                })
            )
            .pipe(map((response: any) => response.data));
    }

    loadRequest(id: number, params: { [key: string]: any } = {}): Observable<IRequest> {
        return this.httpService
            .get<any>(
                this.createUrlWithQueryParameters(`requests/${id}`, (qs: QueryStringParameters) => {
                    for (const key of Object.keys(params)) {
                        qs.push(key, params[key]);
                    }
                })
            )
            .pipe(map((response: any) => response.data));
    }

    loadRequestMessages(id: number, params: { [key: string]: any } = {}): Observable<IMessage[]> {
        return this.httpService
            .get<any>(
                this.createUrlWithQueryParameters(`requests/${id}/messages`, (qs: QueryStringParameters) => {
                    for (const key of Object.keys(params)) {
                        qs.push(key, params[key]);
                    }
                })
            )
            .pipe(map((response: any) => response.data));
    }

    loadRequestTasks(id: number, params: { [key: string]: any } = {}): Observable<ITask[]> {
        return this.httpService
            .get<any>(
                this.createUrlWithQueryParameters(`requests/${id}/tasks`, (qs: QueryStringParameters) => {
                    for (const key of Object.keys(params)) {
                        qs.push(key, params[key]);
                    }
                })
            )
            .pipe(map((response: any) => response.data));
    }

    loadRequestKinds(): Observable<IKind[]> {
        return this.httpService
            .get<any>(this.createUrlWithQueryParameters('requests/kinds', (qs: QueryStringParameters) => {}))
            .pipe(map((response: any) => response.data));
    }

    createMessage(requestId: number, data?: any): Observable<IMessage> {
        return this.httpService
            .post(this.createUrl(`requests/${requestId}/messages`), data)
            .pipe(map((response: any) => response.data));
    }

    createTask(requestId: number, data?: any): Observable<ITask> {
        return this.httpService
            .post(this.createUrl(`requests/${requestId}/tasks`), data)
            .pipe(map((response: any) => response.data));
    }

    deleteRequest(requestId: number): Observable<IRequest> {
        return this.httpService
            .delete(this.createUrl(`requests/${requestId}`))
            .pipe(map((response: any) => response.data));
    }

    updateTasksOrder(requestId: number, data: any): Observable<ITask> {
        return this.httpService
            .post(this.createUrl(`requests/${requestId}/tasks/updateOrder`), data)
            .pipe(map((response: any) => response.data));
    }

    updateRequest(requestId: number, data: any, params: { [key: string]: any } = {}): Observable<IRequest> {
        return this.httpService
            .put(
                this.createUrlWithQueryParameters(`requests/${requestId}`, (qs: QueryStringParameters) => {
                    for (const key of Object.keys(params)) {
                        qs.push(key, params[key]);
                    }
                }),
                data
            )
            .pipe(map((response: any) => response.data));
    }

    updateRequestTask(requestId: number, taskId: number, data: any): Observable<ITask> {
        return this.httpService
            .put(this.createUrl(`requests/${requestId}/tasks/${taskId}`), data)
            .pipe(map((response: any) => response.data));
    }

    updateRequestMessage(requestId: number, messageId: number, data: any): Observable<IMessage> {
        return this.httpService
            .put(this.createUrl(`requests/${requestId}/messages/${messageId}`), data)
            .pipe(map((response: any) => response.data));
    }

    createRequest(data: any, params: { [key: string]: any } = {}): Observable<IRequest> {
        return this.httpService
            .post(
                this.createUrlWithQueryParameters('requests', (qs: QueryStringParameters) => {
                    for (const key of Object.keys(params)) {
                        qs.push(key, params[key]);
                    }
                }),
                data
            )
            .pipe(map((response: any) => response.data));
    }

    addAttachment(requestId: number, data: any): Observable<any> {
        return this.httpService
            .post(this.createUrl(`requests/${requestId}/attachments`), data)
            .pipe(map((response: any) => response.data));
    }

    deleteAttachment(requestId: number, attachmentId: number): Observable<any> {
        return this.httpService
            .delete(this.createUrl(`requests/${requestId}/attachments/${attachmentId}`))
            .pipe(map((response: any) => response.data));
    }

    toggleFavorite(requestId: number): Observable<IRequest> {
        return this.httpService
            .post(this.createUrl(`requests/${requestId}/toggle-favorite`), {})
            .pipe(map((response: any) => response.data));
    }
}
