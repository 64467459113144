import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { FuseFullscreenModule } from '@fuse/components/fullscreen';
import { FuseLoadingBarModule } from '@fuse/components/loading-bar';
import { NotificationsModule } from 'app/layout/common/notifications/notifications.module';
import { SearchModule } from 'app/layout/common/search/search.module';
import { ShortcutsModule } from 'app/layout/common/shortcuts/shortcuts.module';
import { SharedModule } from 'app/shared/shared.module';
import { ModernLayoutComponent } from 'app/layout/layouts/horizontal/modern/modern.component';
import { TasksModule } from '../../../common/tasks/tasks.module';
import { UserMenuModule } from '../../../common/user-menu/user-menu.module';
import { LetDirective, PushPipe } from '@ngrx/component';
import { RequestsCreateModule } from '../../../../modules/common/requests/create/create.module';
import { LogoModule } from '../../../common/organisation-selector/logo.module';
import { NavigationModule } from '../../../navigation';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';
import { ThemeSwitcherModule } from '../../../common/theme-switcher/theme-switcher.module';
import { DynSidebarComponent } from '@shared/components/dyn-sidebar/dyn-sidebar.component';

@NgModule({
    declarations: [ModernLayoutComponent],
    imports: [
        HttpClientModule,
        RouterModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        FuseFullscreenModule,
        FuseLoadingBarModule,
        NavigationModule,
        NotificationsModule,
        SearchModule,
        ShortcutsModule,
        UserMenuModule,
        SharedModule,
        TasksModule,
        LetDirective,
        PushPipe,
        RequestsCreateModule,
        LogoModule,
        TourMatMenuModule,
        ThemeSwitcherModule,
        DynSidebarComponent
    ],
    exports: [ModernLayoutComponent]
})
export class ModernLayoutModule {}
