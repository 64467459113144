import * as L from 'leaflet';

export class DataMarker extends L.Marker {
    data: any;

    constructor(latLng: L.LatLngExpression, options?: L.MarkerOptions, data?: any) {
        super(latLng, options);
        this.setData(data);
    }

    getData(): any {
        return this.data;
    }

    setData(data: any): void {
        this.data = data;
    }
}
