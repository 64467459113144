import { ComponentStore } from '@ngrx/component-store';
import { Injectable } from '@angular/core';
import { switchMap, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { INotification } from '@models/notification';
import { NotificationService } from '@core/services/resource/notification.service';
import { IPagination } from '@models/pagination';

export interface NotificationsState {
    notifications?: IPagination<INotification>;
    count: number;
    processing: boolean;
}

@Injectable()
export class NotificationsStore extends ComponentStore<NotificationsState> {
    readonly notifications$ = this.select(state => state.notifications);
    readonly count$ = this.select(state => state.count);
    readonly processing$ = this.select(state => state.processing);

    readonly setProcessing = this.updater(
        (state: NotificationsState, processing: boolean) => ({
            ...state,
            processing
        })
    );

    readonly updateNotifications = this.updater(
        (state: NotificationsState, notifications: IPagination<INotification>) => ({
            ...state,
            notifications,
            count: notifications.total
        })
    );

    readonly increaseCount = this.updater((state: NotificationsState) => ({
        ...state,
        count: state.count + 1
    }));

    readonly decreaseCount = this.updater((state: NotificationsState) => ({
        ...state,
        count: state.count - 1
    }));

    readonly addNotification = this.updater(
        (state: NotificationsState, notification: INotification) => ({
            ...state,
            notifications: {
                ...state.notifications,
                data: [notification, ...state.notifications.data]
            },
            count: state.count + 1
        })
    );

    readonly loadNotifications = this.effect<void>(trigger$ =>
        trigger$.pipe(
            tap(() => this.setProcessing(true)),

            switchMap(() =>
                this.notificationService.loadNotifications().pipe(
                    tap({
                        next: notifications =>
                            this.updateNotifications(notifications)
                    })
                )
            ),
            tap(() => this.setProcessing(false))
        )
    );

    readonly deleteNotification = this.effect((id$: Observable<string>) =>
        id$.pipe(
            tap(() => this.setProcessing(true)),
            switchMap(id =>
                this.notificationService
                    .deleteNotification(id)
                    .pipe(tap({ next: notification => this.loadNotifications() }))
            ),
            tap(() => this.setProcessing(false))
        )
    );

    readonly deleteAllNotifications = this.effect<void>(trigger$ =>
        trigger$.pipe(
            tap(() => this.setProcessing(true)),
            switchMap(() =>
                this.notificationService
                    .deleteAllNotifications()
                    .pipe(tap({ next: data => this.loadNotifications() }))
            ),
            tap(() => this.setProcessing(false))
        )
    );

    constructor(private notificationService: NotificationService) {
        super({ notifications: undefined, count: 0, processing: false });
    }
}
