<div class="fuse-horizontal-navigation-wrapper">
    <ng-container *ngFor="let item of navigation; trackBy: trackByFn">
        <ng-container *ngIf="item.permission; else emptyPermission">
            <ng-container *ngxPermissionsOnly="[item.permission]">
                <ng-container *ngTemplateOutlet="navItem; context: { item }"></ng-container>
            </ng-container>
        </ng-container>

        <ng-template #emptyPermission>
            <ng-container *ngTemplateOutlet="navItem; context: { item }"></ng-container>
        </ng-template>
    </ng-container>
</div>

<ng-template #navItem let-item="item">
    <!-- Skip the hidden items -->
    <ng-container *ngIf="(item.hidden && !item.hidden(item)) || !item.hidden">
        <!-- Basic -->
        <ng-container *ngIf="item.type === 'basic'">
            <fuse-horizontal-navigation-basic-item
                class="fuse-horizontal-navigation-menu-item"
                [item]="item"
                [name]="name"
                [tourAnchor]="item.tourGuideStepId || null"
            ></fuse-horizontal-navigation-basic-item>
        </ng-container>

        <!-- Branch: aside, collapsable, group -->
        <ng-container *ngIf="item.type === 'aside' || item.type === 'collapsable' || item.type === 'group'">
            <fuse-horizontal-navigation-branch-item
                class="fuse-horizontal-navigation-menu-item"
                [item]="item"
                [name]="name"
                [tourAnchor]="item.tourGuideStepId || null"
            ></fuse-horizontal-navigation-branch-item>
        </ng-container>

        <!-- Spacer -->
        <ng-container *ngIf="item.type === 'spacer'">
            <fuse-horizontal-navigation-spacer-item
                class="fuse-horizontal-navigation-menu-item"
                [item]="item"
                [name]="name"
                [tourAnchor]="item.tourGuideStepId || null"
            ></fuse-horizontal-navigation-spacer-item>
        </ng-container>
    </ng-container>
</ng-template>
