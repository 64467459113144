import { createReducer, on } from '@ngrx/store';
import { IPartner, Partner } from '@models/partner';
import * as PartnerActions from './partner.actions';

export interface IPartnerState {
    partner: IPartner;
    loading: boolean;
}

export const initialState: IPartnerState = {
    partner: new Partner(),
    loading: false
};

export const partnerReducer = createReducer(
    initialState,
    on(PartnerActions.loadPartner, state => ({
        ...state,
        loading: true
    })),
    on(PartnerActions.loadPartnerFailure, state => ({
        ...state,
        loading: false
    })),
    on(PartnerActions.loadPartnerSuccess, (state, { partner }) => ({
        ...state,
        partner
    })),
    on(PartnerActions.updatePartnerProperties, (state, { properties }) => ({
        ...state,
        user: state.partner ? { ...state.partner, ...properties } : null
    }))
);
