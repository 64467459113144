import { Directive, ElementRef, Input, OnChanges } from '@angular/core';
import { getColorFromTailwind, hex2rgba } from '@utils/helpers';

@Directive({
    selector: '[colored]'
})
export class ColoredDirective implements OnChanges {
    @Input() colored: string;
    @Input() bordered = false;
    @Input() onlyText = false; //apply styles only for text color (not background)

    constructor(private el: ElementRef) {}

    ngOnChanges(): void {
        if (this.colored) {
            this.setColor(this.colored);
        }
    }

    private setColor(color: string): void {
        if (this.isTailwind(color)) {
            color = getColorFromTailwind(color);
        }

        if (this.bordered) {
            this.el.nativeElement.style.backgroundColor = hex2rgba(color, 0.7);
            this.el.nativeElement.style.color = this.getTextColor(color);
            this.el.nativeElement.style.border = '2px solid';
            this.el.nativeElement.style.borderColor = color;
            return;
        }

        if (!this.onlyText) {
            this.el.nativeElement.style.backgroundColor = color;
        }
        this.el.nativeElement.style.color = this.getTextColor(color);
    }

    private isTailwind(color: string): boolean {
        return !color.startsWith('#') && color.includes('-');
    }

    private getTextColor(bgColor): string {
        const color = bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor;

        return 150 <
            parseInt(color.substring(0, 2), 16) * 0.299 +
                parseInt(color.substring(2, 4), 16) * 0.587 +
                parseInt(color.substring(4, 6), 16) * 0.114
            ? '#333'
            : 'white';
    }
}
