<button mat-icon-button (click)="openPanel()" #notificationsOrigin *ngrxLet="count$ as count">
    <ng-container *ngIf="count > 0">
        <span class="absolute top-0 right-0 left-0 flex items-center justify-center h-3">
            <span class="flex items-center justify-center shrink-0-0 min-w-4 h-4 px-1 ml-4 mt-2.5 rounded-full bg-indigo-600 text-indigo-50 text-xs font-medium">
                {{ count }}
            </span>
        </span>
    </ng-container>
    <mat-icon [svgIcon]="'heroicons_outline:bell'"></mat-icon>
</button>

<ng-template #notificationsPanel>
    <div class="fixed inset-0 sm:static sm:inset-auto flex flex-col sm:min-w-90 sm:w-90 sm:rounded-md overflow-hidden shadow-lg">
        <!-- Header -->
        <div class="flex shrink-0-0 items-center py-4 pr-4 pl-6 bg-primary text-on-primary" *ngrxLet="count$ as count">
            <div class="sm:hidden -ml-1 mr-3">
                <button mat-icon-button (click)="closePanel()">
                    <mat-icon class="icon-size-5 text-current" [svgIcon]="'heroicons_solid:x'"></mat-icon>
                </button>
            </div>
            <div class="text-lg font-medium leading-10">Notifications</div>
            <div class="ml-auto">
                <button mat-icon-button (click)="closePanel()">
                    <mat-icon class="icon-size-5 text-current" [svgIcon]="'heroicons_solid:x'"></mat-icon>
                </button>
            </div>
        </div>

        <!-- Content -->
        <div class="relative flex flex-col flex-auto sm:max-h-120 divide-y overflow-y-auto bg-card" *ngrxLet="notifications$ as notifications">
            <!-- Messages -->
            <ng-container *ngFor="let notification of notifications.data">
                <div class="flex group hover:bg-gray-50 dark:hover:bg-black dark:hover:bg-opacity-5">
                    <!-- Message with a link -->
                    <ng-container *ngIf="notification.data?.link">
                        <!-- Normal links -->
                        <ng-container>
                            <a class="flex flex-auto py-5 pl-6 cursor-pointer" [href]="notification.data.link" target="_blank">
                                <ng-container *ngTemplateOutlet="notificationContent"></ng-container>
                            </a>
                        </ng-container>
                        <!-- Router links -->
                        <!--                        <ng-container *ngIf="message.useRouter">-->
                        <!--                            <a-->
                        <!--                                    class="flex flex-auto py-5 pl-6 cursor-pointer"-->
                        <!--                                    [routerLink]="message.link">-->
                        <!--                                <ng-container *ngTemplateOutlet="messageContent"></ng-container>-->
                        <!--                            </a>-->
                        <!--                        </ng-container>-->
                    </ng-container>

                    <!-- Message without a link -->
                    <ng-container *ngIf="!notification.data?.link">
                        <div class="flex flex-auto py-5 pl-6">
                            <ng-container *ngTemplateOutlet="notificationContent"></ng-container>
                        </div>
                    </ng-container>

                    <!-- Actions -->
                    <div class="relative flex flex-col my-5 mr-6 ml-2">
                        <!-- Remove -->
                        <button class="w-6 h-6 min-h-6 sm:opacity-0 sm:group-hover:opacity-100" mat-icon-button (click)="delete(notification)" [matTooltip]="'Remove'">
                            <mat-icon class="icon-size-4" [svgIcon]="'heroicons_solid:trash'"></mat-icon>
                        </button>
                    </div>
                </div>

                <!-- Message content template -->
                <ng-template #notificationContent>
                    <!-- Icon -->
                    <ng-container *ngIf="notification.data?.icon && !notification.data?.image">
                        <div class="flex shrink-0-0 items-center justify-center w-8 h-8 mr-4 rounded-full bg-gray-100 dark:bg-gray-700">
                            <mat-icon class="icon-size-5" [svgIcon]="getIcon(notification.data?.icon)"></mat-icon>
                        </div>
                    </ng-container>
                    <!-- Image -->
                    <ng-container *ngIf="notification.data?.image">
                        <img class="shrink-0-0 w-8 h-8 mr-4 rounded-full overflow-hidden object-cover object-center" [src]="notification.data.image" [alt]="'Notification image'" />
                    </ng-container>
                    <!-- Title, message & time -->
                    <div class="flex flex-col flex-auto">
                        <ng-container *ngIf="notification.data?.title">
                            <div class="font-semibold line-clamp-1" [innerHTML]="notification.data?.title"></div>
                        </ng-container>
                        <ng-container *ngIf="notification.data?.message">
                            <div [innerHTML]="notification.data.message"></div>
                        </ng-container>
                        <div class="mt-2 text-sm leading-none text-secondary">
                            {{ notification.created_at | date : "MMM dd, h:mm a" }}
                        </div>
                    </div>
                </ng-template>
            </ng-container>

            <!-- No notifications -->
            <ng-container *ngIf="(count$ | async) === 0">
                <div class="flex flex-col flex-auto items-center justify-center sm:justify-start py-12 px-8">
                    <div class="flex flex-0 items-center justify-center w-14 h-14 rounded-full bg-primary-100">
                        <mat-icon class="text-primary-500-700" [svgIcon]="'heroicons_outline:bell'"></mat-icon>
                    </div>
                    <div class="mt-5 text-2xl font-semibold tracking-tight">No notifications</div>
                    <div class="w-full max-w-60 mt-1 text-md text-center text-secondary">When you have notifications, they will appear here.</div>
                </div>
            </ng-container>
        </div>

        <div class="flex justify-end bg-card p-2 border-t border-solid border-gray-200" *ngIf="(count$ | async) !== 0">
            <button mat-flat-button (click)="deleteAll()">Clear all</button>
        </div>
    </div>
</ng-template>
