import { Directive, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AppCoreDispatchers } from '@store/app-core/app-core.dispatchers';

@Directive({
    selector: '[appRouterLink]'
})
export class RouterLinkDirective {
    @Input() appRouterLink: string;

    // private statefulRoutes = [
    //   '/login', '/login2fa',
    //   '/control-tower', '/profile', '/partner', '/threepl'
    // ];

    constructor(private appCoreDispatchers: AppCoreDispatchers, private router: Router) {}

    @HostListener('click', ['$event']) onClick($event: any): void {
        if (this.appRouterLink) {
            this.navigateToLink();
        }
    }

    private navigateToLink(): void {
        if (this.appRouterLink.charAt(0) === '/') {
            this.router.navigateByUrl(this.appRouterLink);
        } else {
            this.appCoreDispatchers.navigateToUrl(this.appRouterLink);
        }
    }
}
