import { Component, OnDestroy, OnInit, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { Observable, Subject, takeUntil } from 'rxjs';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { FuseNavigationService, NavigationItem } from '../../../navigation';
import { NavigationService } from '@core/services/services/navigation.service';
import { IUser } from '@models/user';
import { IOrganisation } from '@models/organisation';
import { AppCoreSelectors } from '@store/app-core';
import { AuthSelectors } from '@store/auth';
import { SettingsSelectors } from '@store/settings';
import { GuideService } from '@core/services/services/guide.service';
import { SidebarService } from '@core/services/services/sidebar.service';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
    selector: 'modern-layout',
    templateUrl: './modern.component.html',
    styleUrls: ['./modern.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ModernLayoutComponent implements OnInit, OnDestroy {
    @ViewChild(MatSidenav, { static: true }) sidebar: MatSidenav;
    @ViewChild('sidebarContent', { static: true, read: ViewContainerRef })
    sidebarContent: ViewContainerRef;

    isScreenSmall: boolean;
    navigation: NavigationItem[] = [];

    metainfo$: Observable<any>;
    user$: Observable<IUser>;
    organisation$: Observable<IOrganisation>;
    organisations$: Observable<IOrganisation[]>;

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(
        private _navigationService: NavigationService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        private coreSelectors: AppCoreSelectors,
        private authSelectors: AuthSelectors,
        private settingsSelectors: SettingsSelectors,
        public guideService: GuideService,
        public sidebarService: SidebarService
    ) {
        this.metainfo$ = coreSelectors.metainfo$;
        this.user$ = authSelectors.user$;
        this.organisation$ = settingsSelectors.organisation$;
    }

    get currentYear(): number {
        return new Date().getFullYear();
    }

    ngOnInit(): void {
        // Subscribe to navigation data
        this._navigationService.navigation$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((navigation: NavigationItem[]) => {
                this.navigation = navigation;
            });

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {
                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });

        this.sidebarService.init(this.sidebar, this.sidebarContent);
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }
}
