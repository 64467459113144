import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
    AbstractControl,
    FormBuilder,
    FormControl,
    FormGroup,
    FormGroupDirective,
    NgForm,
    ValidationErrors,
    ValidatorFn,
    Validators
} from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorStateMatcher } from '@angular/material/core';
import { AuthService } from '@core/services/resource/auth.service';
import { omit } from 'lodash-es';
import { SnackbarService } from '@core/services/services/snackbar.service';

export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
        const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);

        return invalidCtrl || invalidParent;
    }
}

@Component({
    selector: 'app-auth-accept-invitation',
    templateUrl: './accept-invitation.component.html',
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class AcceptInvitationComponent implements OnInit {
    matcher = new MyErrorStateMatcher();

    acceptInvitationForm: FormGroup;
    showAlert: boolean = false;

    token = null;

    processing = false;

    constructor(
        private _formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private authService: AuthService,
        private snackBar: SnackbarService,
        private router: Router
    ) {}

    ngOnInit(): void {
        // Create the form
        this.acceptInvitationForm = this._formBuilder.group(
            {
                email: ['', [Validators.required, Validators.email]],
                name: ['', [Validators.required]],
                password: ['', Validators.required],
                confirmPassword: ['', Validators.required]
            },
            { validators: this.checkPasswords }
        );

        this.route.queryParams.subscribe(params => {
            this.token = params.token;
            if (params.email) {
                this.acceptInvitationForm.get('email').setValue(params.email);
            }
        });
    }

    checkPasswords: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
        const pass = group.get('password').value;
        const confirmPass = group.get('confirmPassword').value;
        return pass === confirmPass ? null : { notSame: true };
    };

    signIn(): void {
        const data = omit(this.acceptInvitationForm.value, ['confirmPassword']);
        data.token = this.token;
        this.processing = true;
        this.authService.acceptInvitation(data).subscribe(
            user => {
                this.processing = false;
                this.snackBar.show(
                    'success',
                    `Hi, ${user.name}. Your account is successfully registered. Just sign in`
                );
                this.router.navigateByUrl('/auth/login');
            },
            err => (this.processing = false)
        );
    }
}
