import { Component, Input } from '@angular/core';
import { IDevice } from '@models/device';

@Component({
    selector: 'app-device-preview-temperature',
    template: `
        <ng-container *ngIf="device.vendor.temperature">
            <div class="flex flex-col items-center">
                <mat-icon
                    svgIcon="logivice:temp_medium"
                    class="text-{{ device.temperature !== null ? 'green-600' : 'gray-500' }}"
                    [matTooltip]="expanded ? null : (device.temperature | temperature)"
                ></mat-icon>
                <span *ngIf="device.temperature && expanded">{{ device.temperature | temperature }}</span>
            </div>
        </ng-container>
    `
})
export class DevicePreviewTemperatureComponent {
    @Input() device: IDevice;
    @Input() expanded = true;
}
