import { Injectable } from '@angular/core';
import { ApiHttpService } from '@core/services/services/api-http.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { QueryParams, ResourceService } from '@core/services/resource/resource.service';
import { QueryStringParameters } from '@core/services/services/query-string-parameters';
import { ListableResponseType } from '@core/types/http/listable-response.type';
import { IAddress } from '@models/address';
import { ICustomer } from '@models/customer';
import { IContact } from '@models/contact';
import { IPort } from '@models/generated/api.types';
import { IWarehouse } from '@models/warehouse';

@Injectable({
    providedIn: 'root'
})
export class AddressesService extends ResourceService {
    constructor(protected httpService: ApiHttpService) {
        super();
    }

    loadAddresses(
        params: { [key: string]: any } = {},
        filters: { param: string; operator: string; value: any }[] = []
    ): Observable<ListableResponseType<IAddress>> {
        return this.httpService
            .get<any>(
                this.createUrlWithQueryParameters('addresses', (qs: QueryStringParameters) => {
                    for (const key of Object.keys(params)) {
                        qs.push(key, params[key]);
                    }

                    for (const filter of filters) {
                        qs.push(filter.param, filter.value, filter.operator);
                    }
                })
            )
            .pipe(map((response: any) => response.data));
    }

    storeAddress(data: any): Observable<IAddress> {
        return this.httpService.post(this.createUrl('addresses'), data).pipe(map((response: any) => response.data));
    }

    updateAddress(id, data: any): Observable<IAddress> {
        return this.httpService
            .put(this.createUrl(`addresses/${id}`), data)
            .pipe(map((response: any) => response.data));
    }

    loadCustomers(
        params: { [key: string]: any } = {},
        filters: { param: string; operator: string; value: any }[] = []
    ): Observable<ListableResponseType<ICustomer>> {
        return this.httpService
            .get<any>(
                this.createUrlWithQueryParameters('customers', (qs: QueryStringParameters) => {
                    for (const key of Object.keys(params)) {
                        qs.push(key, params[key]);
                    }

                    for (const filter of filters) {
                        qs.push(filter.param, filter.value, filter.operator);
                    }
                })
            )
            .pipe(map((response: any) => response.data));
    }

    loadCustomerHelperData(customerId: number, type, params: { [key: string]: any } = {}): Observable<any> {
        return this.httpService
            .get<any>(
                this.createUrlWithQueryParameters(
                    `customers/${customerId}/helper/${type}`,
                    (qs: QueryStringParameters) => {
                        for (const key of Object.keys(params)) {
                            qs.push(key, params[key]);
                        }
                    }
                )
            )
            .pipe(map((response: any) => response.data));
    }

    storeCustomer(data: ICustomer): Observable<ICustomer> {
        return this.httpService.post(this.createUrl('customers'), data).pipe(map((response: any) => response.data));
    }

    updateCustomer(customer_id: number, data: ICustomer): Observable<ICustomer> {
        return this.httpService
            .put(this.createUrl(`customers/${customer_id}`), data)
            .pipe(map((response: any) => response.data));
    }

    loadContacts(params: { [key: string]: any } = {}): Observable<ListableResponseType<IContact>> {
        return this.httpService
            .get<any>(
                this.createUrlWithQueryParameters(
                    params.address_id ? `addresses/${params.address_id}/contacts` : 'contacts',
                    (qs: QueryStringParameters) => {
                        delete params['address_id'];
                        for (const key of Object.keys(params)) {
                            qs.push(key, params[key]);
                        }
                    }
                )
            )
            .pipe(map((response: any) => response.data));
    }

    loadPorts(params: { [key: string]: any } = {}): Observable<ListableResponseType<IPort>> {
        return this.httpService
            .get<any>(this.createUrl('autocomplete', { subject_type: 'port', ...params }))
            .pipe(map((response: any) => response.data));
    }

    loadWarehouses(params: { [key: string]: any } = {}): Observable<ListableResponseType<IWarehouse>> {
        return this.httpService
            .get<any>(this.createUrl('autocomplete', { subject_type: 'warehouse', ...params }))
            .pipe(map((response: any) => response.data));
    }

    storeContact(addressId: number, data: any): Observable<IContact> {
        return this.httpService
            .post(this.createUrl(`addresses/${addressId}/contacts`), data)
            .pipe(map((response: any) => response.data));
    }

    updateContact(addressId: number, contactId: number, data: any): Observable<IContact> {
        return this.httpService
            .put(this.createUrl(`addresses/${addressId}/contacts/${contactId}`), data)
            .pipe(map((response: any) => response.data));
    }

    deleteCustomer(customerId: number, data: QueryParams = {}): Observable<IContact> {
        return this.httpService
            .delete(this.createUrl(`customers/${customerId}`), data)
            .pipe(map((response: any) => response.data));
    }

    deleteAddress(addressId: number, data: QueryParams = {}): Observable<IContact> {
        return this.httpService
            .delete(this.createUrl(`addresses/${addressId}`), data)
            .pipe(map((response: any) => response.data));
    }

    deleteContact(addressId: number, contactId: number, data: QueryParams = {}): Observable<IContact> {
        return this.httpService
            .delete(this.createUrl(`addresses/${addressId}/contacts/${contactId}`), data)
            .pipe(map((response: any) => response.data));
    }
}
