import { Injectable } from '@angular/core';
import { AuthSelectors } from '@store/auth';
import { UserPreferences } from '@models/user';
import { DistanceUnit, TemperatureUnit } from '@shared/types/units';

@Injectable({
    providedIn: 'root'
})
export class UserPreferencesService {
    private preferences: UserPreferences;
    private temperatureMs: TemperatureUnit;
    private distanceMs: DistanceUnit;

    constructor(private authSelectors: AuthSelectors) {
        this.authSelectors.user$.subscribe(user => {
            this.preferences = user?.settings ?? {
                scheme: 'light',
                distance_measurement: 'km',
                temperature_measurement: 'celsius'
            };

            this.temperatureMs = user?.settings?.temperature_measurement ?? 'celsius';
            this.distanceMs = user?.settings?.distance_measurement ?? 'km';
        });
    }

    temperatureMeasurementUnit(): TemperatureUnit {
        return this.temperatureMs;
    }

    distanceMeasurementUnit(): DistanceUnit {
        return this.distanceMs;
    }

    convertTemperature(celsius: number): number {
        if (celsius && this.temperatureMs === 'fahrenheit') {
            return Math.round((celsius * 1.8 + 32) * 10) / 10;
        }

        return celsius ? Math.round(celsius * 10) / 10 : null;
    }

    revertTemperature(temperature: number): number {
        if (temperature && this.temperatureMs === 'fahrenheit') {
            return (temperature - 32) / 1.8;
        }

        return temperature;
    }

    convertDistance(km: number): number {
        if (km && this.distanceMs === 'mile') {
            return Math.round(km * 0.621371192 * 10) / 10;
        }

        return km;
    }

    revertDistance(distance: number): number {
        if (distance && this.distanceMs === 'mile') {
            return distance * 1.609344;
        }

        return distance;
    }

    formatTemperature(celsius): string {
        return this.convertTemperature(celsius) + (this.temperatureMs === 'celsius' ? ' °C' : ' °F');
    }

    formatDistance(km: number): string {
        return this.convertDistance(km) + (this.distanceMs === 'km' ? ' km' : ' mile');
    }
}
