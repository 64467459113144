import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { SidebarService } from '@core/services/services/sidebar.service';
import { MatIconModule } from '@angular/material/icon';
import { NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'app-dyn-sidebar',
    standalone: true,
    imports: [MatSidenavModule, MatIconModule, NgIf, MatButtonModule],
    templateUrl: './dyn-sidebar.component.html',
    styleUrls: ['./dyn-sidebar.component.scss']
})
export class DynSidebarComponent implements OnInit {
    @ViewChild(MatSidenav, { static: true }) sidebar: MatSidenav;

    @ViewChild('sidebarContent', { static: true, read: ViewContainerRef })
    sidebarContent: ViewContainerRef;

    constructor(public sidebarService: SidebarService) {}

    ngOnInit(): void {
        this.sidebarService.init(this.sidebar, this.sidebarContent);
    }

    cancel(): void {
        this.sidebarService.close();
    }
}
