import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filter'
})
export class FilterPipe implements PipeTransform {
    transform(items: any[], searchText: string, properties: string[]): any[] {
        // return empty array if array is falsy
        if (!items) {
            return [];
        }

        // return the original array if search text is empty
        if (!searchText) {
            return items;
        }

        // convert the searchText to lower case
        searchText = searchText.toLowerCase();

        return items.filter(item => {
            let itemFound: boolean;
            // eslint-disable-next-line @typescript-eslint/prefer-for-of
            for (let i = 0; i < properties.length; i++) {
                if (item[properties[i]].toLowerCase().indexOf(searchText) !== -1) {
                    itemFound = true;
                    break;
                }
            }
            return itemFound;
        });
    }
}
