import { createReducer, on } from '@ngrx/store';
import * as AuthActions from './auth.actions';
import { User } from '@models/user';
import { Subject } from 'rxjs';

export interface IAuthState {
    token: string | null;
    auth: string | null;
    loggedIn: boolean;
    user: User | null;
    loading: boolean;
    error?: string | null;
    two_factor: Subject<boolean>;
}

export const initialState: IAuthState = {
    user: null,
    token: null,
    auth: null,
    loggedIn: false,
    loading: false,
    error: null,
    two_factor: new Subject<boolean>()
};

export const authReducer = createReducer(
    initialState,
    on(AuthActions.loginSuccess, (state, { two_factor }) => ({
        ...state,
        error: null,
        loading: false
    })),
    on(AuthActions.logout, state => ({
        ...state,
        loading: true
    })),
    on(AuthActions.logoutSuccess, () => initialState),
    on(AuthActions.login, state => ({
        ...state,
        error: null,
        loading: true
    })),

    on(AuthActions.loginFailure, (state, { error }) => ({
        ...state,
        error,
        loading: false
    })),
    on(AuthActions.setToken, (state, { token }) => ({ ...state, token })),
    on(AuthActions.invalidateToken, () => initialState),

    // Load user
    on(AuthActions.loadUser, state => ({
        ...state,
        error: null,
        loading: true
    })),
    on(AuthActions.loadUserSuccess, (state, { user }) => ({
        ...state,
        user,
        loggedIn: true,
        error: null,
        loading: false
    })),
    on(AuthActions.loadUserFailure, (state, { message }) => ({
        ...state,
        user: null,
        error: message,
        loading: false
    })),
    on(AuthActions.updateUserSuccess, (state, { user }) => ({
        ...state,
        user: state.user ? { ...state.user, ...user } : null
    })),
    on(AuthActions.authBroadcastSuccess, (state, { auth }) => ({
        ...state,
        auth
    })),
    on(AuthActions.updateUserProperties, (state, { properties }) => ({
        ...state,
        user: state.user ? { ...state.user, ...properties } : null
    }))
);
