import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'url2link'
})
export class Url2linkPipe implements PipeTransform {
    public transform(value: string): string {
        if (!value) {
            return '';
        }
        const urlRegex: RegExp = new RegExp(
            /(https?:\/\/(www\.)?[-a-zA-Z0-9_]{1,256}\.[a-zA-Z0-9]{1,6}\b([-a-zA-Z0-9@\[\]:%_\+.~#?&\//=]*))/gm
        );

        return value.replace(urlRegex, '<a href="$1" target="_blank">$1</a>');
    }
}
