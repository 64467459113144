import { createReducer, on } from '@ngrx/store';
import { DEFAULT_SCHEME, DEFAULT_TEMPLATE, DEFAULT_THEME } from './app.themes';
import { migrateReducerState } from '../store.utils';
import * as AppCoreActions from './app-core.actions';
import { IMentionUser } from '@models/user';
import { Scheme } from '@core/config/app.config';

export interface IAppCore {
    style: 'vertical' | 'horizontal';
    colorTheme: string;
    scheme: Scheme;
    navigation: string;
    navbar: {
        primaryBackground: string;
        secondaryBackground: string;
        folded: boolean;
        hidden: boolean;
    };
    toolbar: {
        background: string | null;
        hidden: boolean;
    };
    sidepanel: {
        hidden: boolean;
    };
    metainfo: {
        subject: string;
        logo: string;
        icon: string;
        type: 'uops' | 'threepl' | 'cockpit' | 'ucontrol' | null;
        slug?: string | null;
        mentions?: IMentionUser[];
    };

    titleVars: object | null;
}

export const newInitialState: IAppCore = {
    style: DEFAULT_TEMPLATE,
    scheme: DEFAULT_SCHEME,
    colorTheme: DEFAULT_THEME,
    navigation: 'cockpit',
    navbar: {
        primaryBackground: 'primary-300',
        secondaryBackground: 'primary-900',
        folded: true,
        hidden: false
    },
    toolbar: {
        background: 'app-white-500',
        hidden: false
    },
    sidepanel: {
        hidden: false
    },
    metainfo: {
        subject: 'Logivice',
        logo: 'assets/images/logos/logo.png',
        icon: 'assets/images/logos/icon.png',
        type: null,
        slug: null
    },
    titleVars: null
};
const initialState: IAppCore = migrateReducerState('appLayout', newInitialState, localStorage);

export const coreReducer = createReducer(
    initialState,
    on(AppCoreActions.toggleNavbar, state => ({
        ...state,
        navbar: {
            ...state.navbar,
            folded: !state.navbar.folded
        }
    })),
    on(AppCoreActions.changeOrientation, (state, { orientation }) => ({
        ...state,
        style: orientation
    })),
    on(AppCoreActions.changeTheme, (state, { theme }) => ({
        ...state,
        colorTheme: theme
    })),
    on(AppCoreActions.changeScheme, (state, { scheme }) => ({
        ...state,
        scheme
    })),
    on(AppCoreActions.changeNavbar, (state, { navbar }) => ({
        ...state,
        navbar: { ...state.navbar, ...navbar }
    })),
    on(AppCoreActions.changeMetainfo, (state, { metainfo }) => ({
        ...state,
        metainfo: { ...state.metainfo, ...metainfo, mentions: undefined }
    })),
    on(AppCoreActions.changeToolbar, (state, { toolbar }) => ({
        ...state,
        toolbar: { ...state.toolbar, ...toolbar }
    })),
    on(AppCoreActions.changeSidepanel, (state, { sidepanel }) => ({
        ...state,
        sidepanel: { ...state.sidepanel, ...sidepanel }
    })),
    on(AppCoreActions.changeSidepanel, (state, { sidepanel }) => ({
        ...state,
        sidepanel: { ...state.sidepanel, ...sidepanel }
    })),
    on(AppCoreActions.loadMentionsSuccess, (state, { mentions }) => ({
        ...state,
        metainfo: { ...state.metainfo, mentions }
    })),
    on(AppCoreActions.setTitleVars, (state, { vars }) => ({
        ...state,
        titleVars: vars
    }))
);
