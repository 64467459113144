import { Injectable } from '@angular/core';
import { ApiHttpService } from '@core/services/services/api-http.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResourceService } from '@core/services/resource/resource.service';
import { QueryStringParameters } from '@core/services/services/query-string-parameters';

@Injectable({
    providedIn: 'root'
})
export class GeoService extends ResourceService {
    constructor(protected httpService: ApiHttpService) {
        super();
    }

    geocode(query: string): Observable<{ lat: number; lng: number }> {
        return this.httpService
            .get<any>(
                this.createUrlWithQueryParameters('geocoding/geocode', (qs: QueryStringParameters) => {
                    qs.push('query', query);
                })
            )
            .pipe(map((response: any) => response.data));
    }

    reverse(lat: number, lng: number): Observable<any> {
        return this.httpService
            .get<any>(
                this.createUrlWithQueryParameters('geocoding/reverse', (qs: QueryStringParameters) => {
                    qs.push('lat', lat);
                    qs.push('lng', lng);
                })
            )
            .pipe(map((response: any) => response.data));
    }
}
