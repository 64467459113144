import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@core/services/resource/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackbarService } from '@core/services/services/snackbar.service';
import { FuseValidators } from '../../../../../@fuse/validators';
import { finalize } from 'rxjs';

interface PasswordResetForm {
    password: FormControl<string>;
    password_confirmation: FormControl<string>;
}

@Component({
    selector: 'app-auth-reset-password',
    templateUrl: './reset-password.component.html'
})
export class ResetPasswordComponent {
    resetPasswordForm = new FormGroup<PasswordResetForm>(
        {
            password: new FormControl('', [Validators.required]),
            password_confirmation: new FormControl('', [Validators.required])
        },
        {
            validators: FuseValidators.mustMatch('password', 'password_confirmation')
        }
    );

    constructor(
        private authService: AuthService,
        private route: ActivatedRoute,
        private router: Router,
        private snackbarService: SnackbarService
    ) {}

    resetPassword(): void {
        if (this.resetPasswordForm.invalid) {
            this.snackbarService.show('error', 'Please enter passwords');
            return;
        }

        this.resetPasswordForm.disable();

        const data = {
            ...this.resetPasswordForm.getRawValue(),
            token: this.route.snapshot.params.token,
            email: this.route.snapshot.queryParams.email
        };

        this.authService
            .resetPassword(data)
            .pipe(finalize(() => this.resetPasswordForm.enable()))
            .subscribe(response => {
                this.router.navigate(['/auth/login']);
                this.snackbarService.show('success', response.message || 'Success, try to login');
            });
    }
}
