<div class="flex justify-between items-center group w-8 hover:w-22 p-2 hover:bg-hover hover:rounded-full transition-all duration-300 ease-in-out" *ngrxLet="scheme$; let scheme">
    <mat-icon [svgIcon]="scheme === 'light' ? 'heroicons_outline:sun' : 'heroicons_outline:moon'" class="group-hover:hidden"></mat-icon>

    <div
        class="hidden group-hover:flex p-1 items-center justify-center rounded-full hover:bg-card"
        [class.hover:bg-gray-500]="scheme === 'light'"
        [class.bg-gray-500]="scheme === 'light'"
        (click)="changeScheme('light')"
    >
        <mat-icon svgIcon="heroicons_outline:sun" class="text-default"></mat-icon>
    </div>

    <div
        class="hidden group-hover:flex p-1 items-center justify-center rounded-full hover:bg-card"
        [class.hover:bg-gray-500]="scheme === 'dark'"
        [class.bg-gray-500]="scheme === 'dark'"
        (click)="changeScheme('dark')"
    >
        <mat-icon svgIcon="heroicons_solid:moon" class="text-default"></mat-icon>
    </div>
</div>
