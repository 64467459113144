import { Component, Input, OnChanges } from '@angular/core';
import { IDevice } from '@models/device';
import moment from 'moment';

@Component({
    selector: 'app-device-preview-opening',
    template: `
        <div class="flex flex-col items-center">
            <mat-icon
                [svgIcon]="device.seal_opened_on ? 'logivice:box_opened' : 'logivice:box_closed'"
                class="text-{{ color }}"
                [matTooltip]="tooltip"
            ></mat-icon>
            <span class="text-sm" *ngIf="expanded && device.last_reply_on !== null">{{
                device.seal_opened_on ? 'Yes' : 'No'
            }}</span>
            <span class="text-xs" *ngIf="expanded && device.seal_opened_on">
                {{ device.seal_opened_on | date : 'M/d/y H:mm' }}
            </span>
        </div>
    `
})
export class DevicePreviewOpeningComponent implements OnChanges {
    @Input() device: IDevice;
    @Input() expanded = true;

    tooltip = null;
    color = 'gray-500';

    ngOnChanges(): void {
        if (this.device.last_reply_on) {
            this.color = this.device.seal_opened_on ? 'red-600' : 'green-600';
        }

        if (!this.expanded) {
            this.tooltip = this.device.seal_opened_on
                ? 'Seal is open! ' + moment(this.device.seal_opened_on).format('M/d/y H:mm')
                : 'Seal is closed';
        }
    }
}
