import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as RouteActions from './router-store.actions';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class RouterEffects {
    navigate$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(RouteActions.go),
                map(action => action),
                tap(({ path, query: queryParams, extras }) => {
                    this.router.navigate(path, { queryParams, ...extras });
                })
            ),
        { dispatch: false }
    );

    navigateBack$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(RouteActions.back),
                tap(() => this.location.back())
            ),
        { dispatch: false }
    );

    navigateForward$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(RouteActions.forward),
                tap(() => this.location.forward())
            ),
        { dispatch: false }
    );

    constructor(private actions$: Actions, private router: Router, private location: Location) {}
}
