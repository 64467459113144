<div
    leaflet
    id="map"
    class="w-full h-full"
    [leafletOptions]="options"
    (leafletMapReady)="onMapReady($event)"
    (leafletMapMoveEnd)="mapBoundsChanged($event)"
    [leafletMarkerCluster]="markerClusterData"
    [leafletMarkerClusterOptions]="markerClusterOptions"
    (leafletMarkerClusterReady)="markerClusterReady($event)"
>
    <div *ngFor="let layer of layers" [leafletLayer]="layer"></div>
    <div leafletDraw [leafletDrawOptions]="drawOptions" (leafletDrawCreated)="onDrawCreated($event)"></div>
</div>

<div #mapLegend class="pb-10 mr-2 space-y-2">
    <div class="flex flex-col" *ngFor="let legend of mapConfiguration.configuration?.legends">
        <div class="flex items-center rounded bg-card px-5 py-1 shadow-2xl space-x-2">
            <div class="w-7 flex items-center">
                <mat-icon [svgIcon]="legend.icon" class="icon-size-5" [style.color]="legend.color"></mat-icon>
            </div>
            <span class="text-gray-600 pt-1 flex flex-col">
                <span>{{ legend.text }}</span>
                <span *ngIf="legend.subtext">{{ legend.subtext }}</span>
            </span>
        </div>
    </div>
</div>
