import { Injectable } from '@angular/core';
import { createSelector, Store } from '@ngrx/store';
import { IAuthState } from '@store/auth/auth.reducer';
import { AppState } from '@store/reducers';
import { IUser } from '@models/user';

export const getAuthState = (state: AppState): IAuthState => state.auth;

export const getUser = createSelector(getAuthState, (state: IAuthState) => state.user);

export const getUserPartners = createSelector(getAuthState, (state: IAuthState) => state.user?.partners ?? []);

export const getUserThreepls = createSelector(getAuthState, (state: IAuthState) => state.user?.threepls ?? []);

export const getToken = createSelector(getAuthState, (state: IAuthState) => state.token);

export const getAuth = createSelector(getAuthState, (state: IAuthState) => state.auth);

export const getLoggedIn = createSelector(getAuthState, (state: IAuthState) => state.loggedIn);

export const getError = createSelector(getAuthState, (state: IAuthState) => state.error);
export const getLoading = createSelector(getAuthState, (state: IAuthState) => state.loading);
export const getTwoFactor = createSelector(getAuthState, (state: IAuthState) => state.two_factor);
export const getOrganisations = createSelector(getUser, (user: IUser) => user?.organisations);

@Injectable()
export class AuthSelectors {
    // selectors$
    user$ = this.store.select(getUser);
    token$ = this.store.select(getToken);
    auth$ = this.store.select(getAuth);
    loggedIn$ = this.store.select(getLoggedIn);
    error$ = this.store.select(getError);
    loading$ = this.store.select(getLoading);
    twoFactor$ = this.store.select(getTwoFactor);
    userPartners$ = this.store.select(getUserPartners);
    userThreepls$ = this.store.select(getUserThreepls);
    organisations$ = this.store.select(getOrganisations);

    constructor(private store: Store<AppState>) {}
}
