import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AddressesService } from '../resource/addresses.service';
import { IAddress } from '@shared/models/address';
import { GeoService } from '@core/services/resource/geo.service';
import { tap } from 'rxjs/operators';

type LatLng = { lat: number; lng: number };

export interface RouteSummary {
    length: number;
    duration?: number;
    baseDuration?: number;
}

@Injectable({
    providedIn: 'root'
})
export class GeocodingService {
    constructor(private addressesService: AddressesService, private geoService: GeoService) {}

    getRouteInformation(
        from: { lat: number; lng: number },
        to: { lat: number; lng: number }
    ): Observable<RouteSummary> {
        return new Observable<RouteSummary>(subscriber => {
            const length = this.getDistanceBetweenPointsInMeter(from, to);

            subscriber.next({ length });
            subscriber.complete();
        });
    }

    getPositionFromAddress(
        address: IAddress,
        saveCoordinates: boolean = true
    ): Observable<{ lat: number; lng: number }> {
        if (address?.lat && address?.lng) {
            return of({ lat: address.lat, lng: address.lng });
        } else {
            return this.getPositionFromAddressString(
                [address.address, address.city, address.country, address.state].filter(a => !!a).join(', ')
            ).pipe(
                tap(coordinates => {
                    if (saveCoordinates) {
                        this.addressesService.updateAddress(address.id, coordinates).subscribe();
                    }
                })
            );
        }
    }

    getPositionFromAddressString(address: string): Observable<{ lat: number; lng: number }> {
        return this.geoService.geocode(address);
    }

    getDistanceBetweenPointsInMeter(from: LatLng, to: LatLng, earthRadius = 6371000): number {
        // convert from degrees to radians
        const latFrom = this.degreesToRadians(from.lat);
        const lonFrom = this.degreesToRadians(from.lng);
        const latTo = this.degreesToRadians(to.lat);
        const lonTo = this.degreesToRadians(to.lng);

        const lonDelta = lonTo - lonFrom;
        const a =
            (Math.cos(latTo) * Math.sin(lonDelta)) ** 2 +
            (Math.cos(latFrom) * Math.sin(latTo) - Math.sin(latFrom) * Math.cos(latTo) * Math.cos(lonDelta)) ** 2;
        const b = Math.sin(latFrom) * Math.sin(latTo) + Math.cos(latFrom) * Math.cos(latTo) * Math.cos(lonDelta);

        const angle = Math.atan2(Math.sqrt(a), b);

        return Math.abs(angle * earthRadius);
    }

    degreesToRadians(degrees): number {
        return degrees * (Math.PI / 180);
    }
}
