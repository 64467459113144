import { Component, Input, OnChanges } from '@angular/core';

@Component({
    selector: 'app-cube-visualization',
    templateUrl: './cube-visualization.component.html',
    styleUrls: ['./cube-visualization.component.scss']
})
export class CubeVisualizationComponent implements OnChanges {
    @Input() position: number[] = [];

    orientation: { pitchX: number; pitchY: number; pitchZ: number };
    transform: string;
    flipped: boolean;

    ngOnChanges(): void {
        const [x, y, z] = this.position;

        this.orientation = this.calculatePitchAngles(x, y, z);
        this.flipped = this.isBoxFlippedDown(x, y, z);

        this.render();
    }

    render(): void {
        this.transform =
            'rotateX(' +
            this.orientation.pitchX +
            'deg) rotateY(' +
            this.orientation.pitchY +
            'deg) rotateZ(' +
            this.orientation.pitchZ +
            'deg)';
    }

    rotateLeft(): void {
        this.orientation.pitchY = (this.orientation.pitchY - 10) % 360;
        this.render();
    }

    rotateRight(): void {
        this.orientation.pitchY = (this.orientation.pitchY + 10) % 360;
        this.render();
    }

    rotateUp(): void {
        this.orientation.pitchX = (this.orientation.pitchX + 10) % 360;
        this.render();
    }

    rotateDown(): void {
        this.orientation.pitchX = (this.orientation.pitchX - 10) % 360;
        this.render();
    }

    calculatePitchAngles(xAxis, yAxis, zAxis): { pitchX: number; pitchY: number; pitchZ: number } {
        const realX = xAxis;
        const realY = zAxis; // Mapping Z-axis to Y in CSS
        const realZ = yAxis; // Mapping Y-axis to Z in CSS

        // Adjustments for the direction of Z-axis
        const zDirection = zAxis >= 0 ? 1 : -1;

        // Pitch for Axis X (rotateX)
        const pitchX = Math.atan2(realX, zDirection * Math.sqrt(realY ** 2 + realZ ** 2)) * (180 / Math.PI);

        // Pitch for Axis Y (rotateY)
        const pitchY = Math.atan2(realY, Math.sqrt(realX ** 2 + realZ ** 2)) * (180 / Math.PI);

        // Pitch for Axis Z (rotateZ)
        const pitchZ = Math.atan2(realZ, Math.sqrt(realX ** 2 + realY ** 2)) * (180 / Math.PI);

        return { pitchX, pitchY, pitchZ };
    }

    isBoxFlippedDown(xAxis, yAxis, zAxis): boolean {
        const realX = xAxis;
        const realY = zAxis; // Mapping Z-axis to Y in CSS
        const realZ = yAxis; // Mapping Y-axis to Z in CSS

        // Adjustments for the direction of Z-axis
        const zDirection = zAxis >= 0 ? 1 : -1;

        // Pitch for Axis X (rotateX)
        const pitchX = Math.atan2(realX, zDirection * Math.sqrt(realY ** 2 + realZ ** 2)) * (180 / Math.PI);

        // Check if the box is flipped down
        const isFlippedDown = Math.abs(pitchX) > 135;

        return isFlippedDown;
    }
}
