import { Component, OnDestroy, OnInit, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { MatSidenav } from '@angular/material/sidenav';
import { SidebarService } from '@core/services/services/sidebar.service';

@Component({
    selector: 'empty-layout',
    templateUrl: './empty.component.html',
    encapsulation: ViewEncapsulation.None
})
export class EmptyLayoutComponent implements OnDestroy, OnInit {
    @ViewChild(MatSidenav, { static: true }) sidebar: MatSidenav;
    @ViewChild('sidebarContent', { static: true, read: ViewContainerRef })
    sidebarContent: ViewContainerRef;

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(public sidebarService: SidebarService) {}

    ngOnInit(): void {
        this.sidebarService.init(this.sidebar, this.sidebarContent);
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}
