import { Injectable } from '@angular/core';
import { ApiHttpService } from '@core/services/services/api-http.service';
import { Observable } from 'rxjs';
import { ResourceService } from '@core/services/resource/resource.service';
import { QueryStringParameters } from '@core/services/services/query-string-parameters';
import { map } from 'rxjs/operators';
import { IPagination } from '@models/pagination';
import { IBackgroundTask } from '@models/background-task';
import { HttpResponse } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class TaskService extends ResourceService {
    constructor(protected httpService: ApiHttpService) {
        super();
    }

    loadTasks(): Observable<IPagination<IBackgroundTask>> {
        return this.httpService
            .get<any>(this.createUrlWithQueryParameters('tasks', (qs: QueryStringParameters) => {}))
            .pipe(map((response: any) => response.data));
    }

    downlaodFile(filepath): Observable<HttpResponse<Blob>> {
        return this.httpService
            .get<Blob>(
                this.createUrlWithQueryParameters('files/download', (qs: QueryStringParameters) => {
                    qs.push('filepath', filepath);
                }),
                { observe: 'response', responseType: 'blob' as 'json' }
            )
            .pipe(map((response: any) => response));
    }

    deleteTask(id: number): Observable<IBackgroundTask> {
        return this.httpService.delete(this.createUrl(`tasks/${id}`), {}).pipe(map((response: any) => response.data));
    }

    deleteAllTasks(): Observable<any> {
        return this.httpService.delete(this.createUrl('tasks'), {}).pipe(map((response: any) => response.data));
    }
}
