import { Pipe, PipeTransform } from '@angular/core';
import { UserPreferencesService } from '@core/services/services/user-preferences.service';

@Pipe({
    name: 'distance'
})
export class DistancePipe implements PipeTransform {
    constructor(private preferencesService: UserPreferencesService) {}

    transform(km: number): string {
        return this.preferencesService.formatDistance(km);
    }
}
