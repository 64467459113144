import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AuthSelectors } from '@store/auth';
import { Observable } from 'rxjs';
import { IOrganisation } from '@models/organisation';
import { animate, style, transition, trigger } from '@angular/animations';
import { filter, take } from 'rxjs/operators';
import { ofType } from '@ngrx/effects';
import * as SettingsActions from '@store/settings/settings.actions';
import { SettingsDispatchers, SettingsSelectors } from '@store/settings';
import { ActionsSubject } from '@ngrx/store';

@Component({
    selector: 'app-entrance',
    templateUrl: './entrance.component.html',
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger('leftSlifeInPaneAnimation', [
            transition(':enter', [
                style({ opacity: 0, transform: 'translateX(-100%)' }), //apply default styles before animation starts
                animate('400ms ease-in-out', style({ opacity: 1, transform: 'translateX(0)' }))
            ])
        ]),
        trigger('rightSlifeInPaneAnimation', [
            transition(':enter', [
                style({ opacity: 0, transform: 'translateX(100%)' }), //apply default styles before animation starts
                animate('400ms ease-in-out', style({ opacity: 1, transform: 'translateX(0)' }))
            ])
        ])
    ]
})
export class EntranceComponent implements OnInit {
    organisations$: Observable<IOrganisation[]>;

    organisation: IOrganisation;

    constructor(
        private authSelectors: AuthSelectors,
        private _router: Router,
        private _settingsDispatchers: SettingsDispatchers,
        private _settingsSelectors: SettingsSelectors,
        private actionListener$: ActionsSubject
    ) {
        this.organisations$ = authSelectors.organisations$;
    }

    ngOnInit(): void {
        this.organisations$
            .pipe(
                filter(o => !!o),
                take(1)
            )
            .subscribe(organisations => {
                if (organisations.length === 1) {
                    this.autoSelectOrganisation(organisations[0]);
                }
            });
    }

    organisationSelected(organisation: IOrganisation): void {
        this.organisation = organisation;
        this.skipEntranceIfPossible();
    }

    private autoSelectOrganisation(organisation): void {
        this._settingsDispatchers.loadOrganisation(organisation.slug);
        this.actionListener$
            .pipe(ofType(SettingsActions.loadOrganisationSuccess, SettingsActions.loadOrganisationFailure), take(1))
            .subscribe(action => {
                if (action.type === '[Settings] Load Organisation Success') {
                    this.organisationSelected(action.organisation);
                }
            });
    }

    private skipEntranceIfPossible(): void {
        const allowedModules = [...new Set(this.organisation.user_permissions.filter(p => !p.includes('.')))];

        if (allowedModules.length === 1) {
            const module = allowedModules[0];

            if (module !== 'partner') {
                //TODO: go to first allowed page in module instead of dashboard
                this._router.navigate(['/o', this.organisation.slug, allowedModules[0], 'dashboard']);
            } else {
                this._settingsSelectors.allowedPartners$.pipe(take(1)).subscribe(p => {
                    if (p.length === 1) {
                        this._router.navigate(['/o', this.organisation.slug, 'partner', p[0].slug, 'dashboard']);
                    }
                });
            }
        } else {
            this._settingsSelectors.allowedPartners$.pipe(take(1)).subscribe(p => {
                if (p.length === 0) {
                    this._router.navigate(['/o', this.organisation.slug, 'cockpit', 'dashboard']);
                }
            });
        }
    }

    // goToOrganisation(organisation: IOrganisation): void {
    //     this._router.navigateByUrl('/o/' + organisation.slug + '/cockpit/dashboard');
    // }
}
