export const iconSet: { [key: string]: { iconUrl: string } } = {
    device: {
        iconUrl: 'assets/icons/modern_rounded/device.svg'
    },
    box_open: {
        iconUrl: 'assets/icons/modern_rounded/opened_box.svg'
    },
    delivered: {
        iconUrl: 'assets/icons/modern_rounded/delivered.svg'
    },
    vessel: {
        iconUrl: 'assets/icons/modern_rounded/vessel.svg'
    },
    plain: {
        iconUrl: 'assets/icons/modern_rounded/plain.svg'
    },
    airport: {
        iconUrl: 'assets/icons/modern_rounded/airport.svg'
    },
    seaport: {
        iconUrl: 'assets/icons/modern_rounded/seaport.svg'
    },
    home: {
        iconUrl: 'assets/icons/modern_rounded/home.svg'
    },
    hub_good: {
        iconUrl: 'assets/icons/modern_rounded/hub_good.svg'
    },
    hub_defective: {
        iconUrl: 'assets/icons/modern_rounded/hub_defective.svg'
    },
    hub_good_defective: {
        iconUrl: 'assets/icons/modern_rounded/hub_good_defective.svg'
    },
    had_shock: {
        iconUrl: 'assets/icons/modern_rounded/had_shock.svg'
    },
    mini_plain: {
        iconUrl: 'assets/icons/modern_mini/plain.svg'
    }
};
