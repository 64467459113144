import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'sort',
    pure: false
})
export class SortPipe implements PipeTransform {
    public transform(items: any[], sortBy: string, direction: 'asc' | 'desc' = 'asc'): any[] {
        if (!items) {
            return [];
        }
        if (!direction) {
            direction = 'asc';
        }

        return [...items].sort((a: any, b: any) => {
            if (a[sortBy] < b[sortBy]) {
                return direction === 'asc' ? -1 : 1;
            } else if (a[sortBy] > b[sortBy]) {
                return direction === 'asc' ? 1 : -1;
            } else {
                return 0;
            }
        });
    }
}
