import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@store/reducers';
import * as AuthActions from './auth.actions';
import { BroadcastChanelRequestType } from '@core/types/http/request.type';

@Injectable()
export class AuthDispatchers {
    constructor(private store: Store<AppState>) {}

    login(credentials: { email: string; password: string; remember: boolean }): void {
        this.store.dispatch(AuthActions.login({ credentials }));
    }

    authBroadcast(channel: BroadcastChanelRequestType): void {
        this.store.dispatch(AuthActions.authBroadcast({ channel }));
    }

    logout(): void {
        this.store.dispatch(AuthActions.logout());
    }

    loadUser(): void {
        this.store.dispatch(AuthActions.loadUser());
    }

    updateUser(data: any): void {
        this.store.dispatch(AuthActions.updateUser({ data }));
    }

    loginRequired(): void {
        this.store.dispatch(AuthActions.loginRedirect());
    }

    storeToken(token: string): void {
        this.store.dispatch(AuthActions.setToken({ token }));
    }

    invalidateToken(): void {
        this.store.dispatch(AuthActions.invalidateToken());
    }

    updateUserProperties(properties: { [key: string]: any }): void {
        this.store.dispatch(AuthActions.updateUserProperties({ properties }));
    }

    twoFactorAuthRequired(): void {
        this.store.dispatch(AuthActions.twoFactorAuthRequired());
    }

    twoFactorChallenge(data: any): void {
        this.store.dispatch(AuthActions.twoFactorSubmit({ data }));
    }

    disableGuide(names: string[]): void {
        this.store.dispatch(AuthActions.disableGuides({ names }));
    }
}
