import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@store/reducers';
import * as PartnerActions from './partner.actions';

@Injectable()
export class PartnerDispatchers {
    constructor(private store: Store<AppState>) {}

    loadPartner(slug: string): void {
        this.store.dispatch(PartnerActions.loadPartner({ slug }));
    }

    updatePartnerProperties(properties: { [key: string]: any }): void {
        this.store.dispatch(PartnerActions.updatePartnerProperties({ properties }));
    }
}
