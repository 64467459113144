import { Injectable, ViewContainerRef } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';

export type SidebarOptions = {
    width?: number;
    header?: string;
    closeBtn?: 'inside' | 'outside' | 'none';
};

@Injectable({
    providedIn: 'root'
})
export class SidebarService {
    width = 500;
    options: SidebarOptions = {};

    private sidebarRef: MatSidenav;
    private sidebarContentRef: ViewContainerRef;
    private subscription: Subscription;

    constructor(private router: Router) {}

    init(sidebar: MatSidenav, sidebarContent: ViewContainerRef): void {
        this.sidebarRef = sidebar;
        this.sidebarContentRef = sidebarContent;
    }

    open<T>(component: any, options: SidebarOptions = {}, data: Record<string, any> = null): Promise<T> {
        this.options = {
            closeBtn: options.header ? 'inside' : 'none',
            ...options
        };

        this.setWidth(options.width ?? null);
        this.listenRouteChanges();

        this.sidebarContentRef.clear();

        this.sidebarRef.opened = true;

        return this.displayComponent<T>(component, data);
    }

    close(): void {
        if (!this.sidebarRef.opened) {
            return;
        }

        this.sidebarContentRef.clear();
        this.sidebarRef.opened = false;

        if (this.subscription && !this.subscription.closed) {
            this.subscription.unsubscribe();
        }
    }

    private listenRouteChanges(): void {
        this.subscription = this.router.events.pipe(take(1)).subscribe(() => this.close());
    }

    private setWidth(width: number = null): void {
        if (window.innerWidth < 700) {
            this.width = window.innerWidth;
            return;
        }

        this.width = width || 500;
    }

    private displayComponent<T>(component: any, data: Record<string, any>): Promise<T> {
        const componentRef = this.sidebarContentRef.createComponent<T>(component);

        if (data) {
            // eslint-disable-next-line guard-for-in
            for (const key in data) {
                componentRef.instance[key] = data[key];
            }
        }

        componentRef.changeDetectorRef.markForCheck();

        return Promise.resolve(componentRef.instance);
    }
}
