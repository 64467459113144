import { Component, computed, Input } from '@angular/core';
import { ApiHttpService } from '@core/services/services/api-http.service';

@Component({
    selector: 'request-pulse',
    templateUrl: './request-pulse.component.html'
})
export class RequestPulseComponent {
    @Input({ required: true }) url: string;

    loading = computed(() => this.httpService.processing().some(url => url.includes(this.url)));

    constructor(private httpService: ApiHttpService) {}
}
