import { Injectable } from '@angular/core';
import { ApiHttpService } from '@core/services/services/api-http.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResourceService } from '@core/services/resource/resource.service';
import { QueryStringParameters } from '@core/services/services/query-string-parameters';
import { IPagination } from '@models/pagination';
import { IUser, IWebHook } from '@models/user';

@Injectable({
    providedIn: 'root'
})
export class UserService extends ResourceService {
    constructor(protected httpService: ApiHttpService) {
        super();
    }

    loadUsers(params: { [key: string]: any } = {}): Observable<IUser[] | IPagination<IUser>> {
        return this.httpService
            .get<any>(
                this.createUrlWithQueryParameters('users', (qs: QueryStringParameters) => {
                    for (const key of Object.keys(params)) {
                        qs.push(key, params[key]);
                    }
                })
            )
            .pipe(map((response: any) => response.data));
    }

    loadUserWebHooks(userId: number, params: { [key: string]: any } = {}): Observable<IWebHook[]> {
        return this.httpService
            .get<any>(this.createUrl(`users/${userId}/webhooks`, { responseFormat: 'list', ...params }))
            .pipe(map((response: any) => response.data));
    }

    createWebHook(userId: number, data: { url: string }): Observable<IWebHook> {
        return this.httpService
            .post<IWebHook>(this.createUrl(`users/${userId}/webhooks`), data)
            .pipe(map((response: any) => response.data));
    }

    deleteWebHook(userId: number, webhookId: number): Observable<IWebHook> {
        return this.httpService
            .delete<any>(this.createUrl(`users/${userId}/webhooks/${webhookId}`))
            .pipe(map((response: any) => response.data));
    }
}
