import { Injectable } from '@angular/core';
import { CanActivateChild, Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AuthSelectors } from '@store/auth';

@Injectable()
export class GuestGuard implements CanActivateChild {
    constructor(
        private authSelectors: AuthSelectors,
        private router: Router // private authDispatchers: AuthDispatchers
    ) {}

    canActivateChild(): Observable<boolean> {
        return this.authSelectors.user$.pipe(
            switchMap(authed => {
                if (authed) {
                    this.router.navigateByUrl('entrance');
                }
                return of(!authed);
            })
        );
    }
}
