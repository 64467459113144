/**
 * migrate reducer strategy
 */
export const migrateReducerState = (
    prevReducerKey: string,
    newReducerState: any,
    storage: { getItem: any; removeItem: any }
): any => {
    const prevReducerState = storage.getItem(prevReducerKey);
    const prevStateJson = prevReducerState && prevReducerState.length > 0 ? JSON.parse(prevReducerState) : false;
    if (prevStateJson) {
        storage.removeItem(prevReducerKey);
    }
    return prevStateJson ? { ...newReducerState, ...prevStateJson } : newReducerState;
};
