import { createReducer, on } from '@ngrx/store';
import { IPartner } from '@models/partner';
import * as SettingsActions from './settings.actions';
import { IOrganisation } from '@models/organisation';
import { xor } from 'lodash-es';

export interface CheckedPartner {
    partner: IPartner;
    checked: boolean;
}

export interface ISettingsState {
    organisations?: IOrganisation[];

    organisation?: IOrganisation;

    selectedPartnersIds: number[];
    submittingSelected: boolean;

    loading: boolean;
}

export const initialState: ISettingsState = {
    // partners: [],
    selectedPartnersIds: [],
    submittingSelected: false,
    loading: false
};

export const settingsReducer = createReducer(
    initialState,

    on(SettingsActions.setInitialSelectedPartners, state => ({
        ...state,
        selectedPartnersIds: state.organisation?.allowed_partners.filter(p => p.is_selected).map(p => p.id)
    })),
    on(SettingsActions.applySelectedPartnersChangesSuccess, state => ({
        ...state,
        organisation: {
            ...state.organisation,
            allowed_partners: state.organisation.allowed_partners.map(p => ({
                ...p,
                is_selected: state.selectedPartnersIds.indexOf(p.id) > -1
            }))
        },
        submittingSelected: false
    })),

    on(SettingsActions.applySelectedPartnersChanges, state => ({
        ...state,
        submittingSelected: true
    })),
    on(SettingsActions.applySelectedPartnersChangesFailure, state => ({
        ...state,
        submittingSelected: false
    })),

    on(SettingsActions.togglePartner, (state, { partner }) => ({
        ...state,
        selectedPartnersIds: xor(state.selectedPartnersIds, [partner.id])
    })),

    on(SettingsActions.toggleAllPartners, state => ({
        ...state,
        selectedPartnersIds:
            state.selectedPartnersIds.length < state.organisation.allowed_partners.length
                ? state.organisation.allowed_partners.map(p => p.id)
                : []
    })),

    on(SettingsActions.loadOrganisation, state => ({
        ...state,
        loading: true
    })),
    on(SettingsActions.loadOrganisationSuccess, (state, { organisation }) => ({
        ...state,
        organisation,
        selectedPartnersIds: organisation.allowed_partners.filter(p => p.is_selected).map(p => p.id),
        loading: false
    })),
    on(SettingsActions.loadOrganisationFailure, state => ({
        ...state,
        loading: false
    })),
    on(SettingsActions.setOrganisations, (state, { organisations }) => ({ ...state, organisations }))
);
