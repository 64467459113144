<div class="range w-full" (click)="openPickerPanel()" #pickerPanelOrigin>
    <div class="start" [class.flex-grow]="flexGrow">
        <div class="date">{{ range.startDate }}</div>
        <div class="time" *ngIf="range.startTime">{{ range.startTime }}</div>
    </div>

    <div class="separator" [class.flex-grow-0]="flexGrow">-</div>

    <div class="end" [class.flex-grow]="flexGrow">
        <div class="date">{{ range.endDate }}</div>
        <div class="time" *ngIf="range.endTime">{{ range.endTime }}</div>
    </div>

    <mat-icon svgIcon="heroicons_outline:calendar" class="ml-auto mr-4" *ngIf="calendarIcon"></mat-icon>
</div>

<ng-template #pickerPanel>
    <!-- Start -->
    <div class="start">
        <div class="month">
            <div class="month-header">
                <button class="previous-button" mat-icon-button (click)="prev()" tabindex="1">
                    <mat-icon [svgIcon]="'heroicons_outline:chevron-left'"></mat-icon>
                </button>
                <div class="month-label">{{ getMonthLabel(1) }}</div>
            </div>
            <mat-month-view
                [(activeDate)]="activeDates.month1"
                [dateFilter]="dateFilter()"
                [dateClass]="dateClass()"
                (click)="$event.stopImmediatePropagation()"
                (selectedChange)="onSelectedDateChange($event)"
                #matMonthView1
            ></mat-month-view>
        </div>

        <mat-form-field class="fuse-mat-no-subscript time start-time" *ngIf="timeRange">
            <input matInput [autocomplete]="'off'" [formControl]="startTimeFormControl" (blur)="updateStartTime($event)" tabindex="3" />
            <mat-label>Start time</mat-label>
        </mat-form-field>
    </div>

    <!-- End -->
    <div class="end">
        <div class="month">
            <div class="month-header">
                <div class="month-label">{{ getMonthLabel(2) }}</div>
                <button class="next-button" mat-icon-button (click)="next()" tabindex="2">
                    <mat-icon [svgIcon]="'heroicons_outline:chevron-right'"></mat-icon>
                </button>
            </div>
            <mat-month-view
                [(activeDate)]="activeDates.month2"
                [dateFilter]="dateFilter()"
                [dateClass]="dateClass()"
                (click)="$event.stopImmediatePropagation()"
                (selectedChange)="onSelectedDateChange($event)"
                #matMonthView2
            ></mat-month-view>
        </div>

        <mat-form-field class="fuse-mat-no-subscript time end-time" *ngIf="timeRange">
            <input matInput [formControl]="endTimeFormControl" (blur)="updateEndTime($event)" tabindex="4" />
            <mat-label>End time</mat-label>
        </mat-form-field>
    </div>
</ng-template>
