import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AuthSelectors } from '@store/auth';
import { SettingsDispatchers } from '@store/settings';
import * as SettingsActions from '@store/settings/settings.actions';
import { IOrganisation } from '@models/organisation';
import { ofType } from '@ngrx/effects';
import { ActionsSubject } from '@ngrx/store';
import { take } from 'rxjs/operators';

@Component({
    selector: 'enter-as-organisation',
    templateUrl: './organisation.component.html',
    styleUrls: ['./organisation.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class EnterAsOrganisationComponent implements OnInit, OnDestroy {
    @Output() organisationSelected = new EventEmitter<IOrganisation>();

    organisations$: Observable<IOrganisation[]>;

    // Private
    private _unsubscribeAll: Subject<any>;

    constructor(
        private _settingsDispatchers: SettingsDispatchers,
        private authSelectors: AuthSelectors,
        private actionListener$: ActionsSubject
    ) {
        this.organisations$ = authSelectors.organisations$;
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        // Subscribe to the foldedChanged observable
    }

    selectOrganisation(organisation: IOrganisation): void {
        this._settingsDispatchers.loadOrganisation(organisation.slug);
        this.actionListener$
            .pipe(ofType(SettingsActions.loadOrganisationSuccess, SettingsActions.loadOrganisationFailure), take(1))
            .subscribe(action => {
                if (action.type === '[Settings] Load Organisation Success') {
                    this.organisationSelected.emit(action.organisation);
                }
            });
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}
