export const Themes = ['theme-default', 'theme-yellow-light', 'theme-blue-gray-dark', 'theme-pink-dark'];
export const Schemes = ['dark', 'light'] as const;

export type Scheme = (typeof Schemes)[number];

export const DEFAULT_THEME = Themes[0];

export const DEFAULT_SCHEME: Scheme = 'light';

export const Templates = ['vertical', 'horizontal'];

export const DEFAULT_TEMPLATE = 'vertical';
