import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'ucfirst'
})
export class UcFirstPipe implements PipeTransform {
    public static transform(value: string): string {
        if (!value) {
            return '';
        }
        value = value.trim();
        const firstChar: string = value.charAt(0).toUpperCase();
        return firstChar + value.substr(1, value.length - 1);
    }

    public transform(value: string): string {
        if (!value) {
            return '';
        }
        value = value.trim();
        const firstChar: string = value.charAt(0).toUpperCase();
        return firstChar + value.substr(1, value.length - 1);
    }
}
