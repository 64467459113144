import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { Injectable } from '@angular/core';
import { AppCoreSelectors } from '@store/app-core';
import { filter, skip } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PageTitleStrategy extends TitleStrategy {
    routerState: RouterStateSnapshot;
    constructor(private readonly title: Title, private readonly coreSelectors: AppCoreSelectors) {
        super();
        this.coreSelectors.titleVars
            .pipe(
                skip(1),
                filter(t => !!t)
            )
            .subscribe(vars => {
                this.title.setTitle(this.buildFullTitle(vars));
            });
    }

    override updateTitle(routerState: RouterStateSnapshot): void {
        this.routerState = routerState;
        const title = this.buildFullTitle();

        if (title) {
            this.title.setTitle(title);
        }
    }

    private buildFullTitle(vars?: object): string | null {
        let targetPageTitle = this.routerState ? this.buildTitle(this.routerState) : null;

        if (!targetPageTitle || (targetPageTitle.includes(':') && !vars)) {
            return null;
        }

        if (vars) {
            Object.keys(vars).forEach(param => {
                targetPageTitle = targetPageTitle.replace(':' + param, vars[param]);
            });
        }

        if (this.routerState.url.slice(0, 2) === '/o') {
            let route = this.routerState.root;

            while (route && !route.params?.organisation) {
                route = route.firstChild;
            }

            if (route.params?.organisation) {
                const routePanel = this.getResolvedTitleForRoute(route.firstChild);
                const routeOrganization = this.capitalize(route.params?.organisation);

                return `[${routePanel}] ${targetPageTitle} | ${routeOrganization} | Logivice`;
            }
        }

        return `${targetPageTitle} | Logivice`;
    }

    private capitalize(text): string {
        return text.charAt(0).toUpperCase() + text.slice(1);
    }
}
