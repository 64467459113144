import { Component, Input } from '@angular/core';
import { IDevice } from '@models/device';

@Component({
    selector: 'app-device-preview-shock',
    template: `
        <div class="flex flex-col items-center">
            <mat-icon svgIcon="iconsmind:pulse" class="text-{{ device | mapper : color }}"></mat-icon>
            <span class="text-sm" *ngIf="expanded">{{ device.shock_magnitude || 'No' }}</span>
            <span class="text-xs" *ngIf="expanded && device.had_shock_on">
                {{ device.had_shock_on | date : 'M/d/y H:mm' }}
            </span>
        </div>
    `
})
export class DevicePreviewShockComponent {
    @Input() device: IDevice;
    @Input() expanded = true;

    color = (device: IDevice): string => {
        const hasShockSensor = device.firmware?.shock || device.vendor?.shock;

        if (!hasShockSensor) {
            return 'gray-500';
        }

        switch (device.profile_configurations?.configurations?.sensitivity) {
            case 'hight':
                return device.shock_magnitude ? 'red-600' : 'green-600';
            case 'medium':
                return device.shock_magnitude >= 3 ? 'red-600' : 'green-600';
            default:
                return device.shock_magnitude >= 10 ? 'red-600' : 'green-600';
        }
    };
}
