import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@core/services/resource/auth.service';
import { SnackbarService } from '@core/services/services/snackbar.service';
import { finalize } from 'rxjs';

interface ForgotPasswordForm {
    email: FormControl<string>;
}

@Component({
    selector: 'app-auth-forgot-password',
    templateUrl: './forgot-password.component.html'
})
export class ForgotPasswordComponent {
    forgotPasswordForm = new FormGroup<ForgotPasswordForm>({
        email: new FormControl('', [Validators.required, Validators.email])
    });

    linkSent = false;

    constructor(private authService: AuthService, private snackbarService: SnackbarService) {}

    sendResetLink(): void {
        if (this.forgotPasswordForm.invalid) {
            this.snackbarService.show('error', 'Please, enter valid email address');
            return;
        }

        this.forgotPasswordForm.disable();

        this.authService
            .sendPasswordResetLink(this.forgotPasswordForm.value.email)
            .pipe(finalize(() => this.forgotPasswordForm.enable()))
            .subscribe(() => (this.linkSent = true));
    }
}
