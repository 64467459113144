import { Pipe, PipeTransform } from '@angular/core';
import { IAddress } from '@models/address';

@Pipe({
    name: 'address'
})
export class AddressPipe implements PipeTransform {
    transform(address: IAddress, type?: 'address' | 'additions' | 'country-iso'): string {
        const addressPart = [address.address, address.address2, address.address3].filter(p => !!p).join(', ');
        const additionsPart = `${address.city}, ${address.zip}, ${address.country}(${address.country_iso_2})`;

        if (type === 'address') {
            return addressPart;
        }
        if (type === 'additions') {
            return additionsPart;
        }
        if (type === 'country-iso') {
            return `${address.country} (${address.country_iso_2})`;
        }

        return `${addressPart}, [${additionsPart}]`;
    }
}
