import { Pipe, PipeTransform } from '@angular/core';
import { getConfigNames } from '@utils/helpers';

@Pipe({
    name: 'getConfNames'
})
export class ItemConfigurationNamesPipe implements PipeTransform {
    public transform(item: { configurations?: string; related_configurations?: { name: string }[] }): string {
        return getConfigNames(item);
    }
}
