import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
    name: 'timeFromNow'
})
export class TimeFromNowPipe implements PipeTransform {
    transform(time: any): string {
        return moment(time).fromNow();
    }
}
