import { Injectable } from '@angular/core';
import { ApiHttpService } from '@core/services/services/api-http.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResourceService } from '@core/services/resource/resource.service';
import { QueryStringParameters } from '@core/services/services/query-string-parameters';
import { ListableResponseType } from '@core/types/http/listable-response.type';
import { IOrganisation } from '@models/organisation';

@Injectable({
    providedIn: 'root'
})
export class OrganisationService extends ResourceService {
    firstInit = true;

    constructor(protected httpService: ApiHttpService) {
        super();
    }

    loadOrganisations(params: { [key: string]: any } = {}): Observable<ListableResponseType<IOrganisation>> {
        return this.httpService
            .get<any>(
                this.createUrlWithQueryParameters('organisations', (qs: QueryStringParameters) => {
                    for (const key of Object.keys(params)) {
                        qs.push(key, params[key]);
                    }
                })
            )
            .pipe(map((response: any) => response.data));
    }

    loadOrganisation(slug: string, params: { [key: string]: any } = {}): Observable<IOrganisation> {
        return this.httpService
            .get(
                this.createUrlWithQueryParameters(`organisations/${slug}`, (qs: QueryStringParameters) => {
                    for (const key of Object.keys(params)) {
                        qs.push(key, params[key]);
                    }
                })
            )
            .pipe(map((response: any) => response.data));
    }

    syncSelectedPartners(slug: string, data: { [key: string]: any } = {}): Observable<any> {
        return this.httpService
            .put(this.createUrl(`organisation/${slug}/partners/sync-selected`), data)
            .pipe(map((response: any) => response.data));
    }
}
