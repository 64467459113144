import { Pipe, PipeTransform } from '@angular/core';
import { convertHMSAsObject } from '@utils/helpers';

@Pipe({
    name: 'timeToText'
})
export class TimeToTextPipe implements PipeTransform {
    transform(value: number, ...args: unknown[]): unknown {
        if (value) {
            const timeParts = convertHMSAsObject(value);

            return `${timeParts.h}h ${timeParts.m}min`;
        }

        return '--';
    }
}
