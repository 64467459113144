import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { Observable, take } from 'rxjs';
import { AppCoreSelectors } from '@store/app-core';
import { Scheme } from '@store/app-core/app.themes';
import { AppCoreDispatchers } from '@store/app-core/app-core.dispatchers';

@Component({
    selector: 'theme-switcher',
    templateUrl: './theme-switcher.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'themeSwitcher'
})
export class ThemeSwitcherComponent {
    scheme$: Observable<string>;

    constructor(private appCoreSelectors: AppCoreSelectors, private appCoreDispatchers: AppCoreDispatchers) {
        this.scheme$ = this.appCoreSelectors.scheme$;
    }

    changeScheme(scheme: Scheme): void {
        this.appCoreSelectors.scheme$.pipe(take(1)).subscribe(currentScheme => {
            if (scheme !== currentScheme) {
                this.appCoreDispatchers.changeScheme(scheme);
            }
        });
    }
}
