import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@store/reducers';
import * as SettingsActions from './settings.actions';
import { IPartner } from '@models/partner';

@Injectable()
export class SettingsDispatchers {
    constructor(private store: Store<AppState>) {}

    // getPartners(): void {
    //     this.store.dispatch(SettingsActions.getPartners());
    // }
    //
    // setPartners(partners: IPartner[]): void {
    //     this.store.dispatch(SettingsActions.setPartners({partners}));
    // }

    togglePartner(partner: IPartner): void {
        this.store.dispatch(SettingsActions.togglePartner({ partner }));
    }

    toggleAllPartners(): void {
        this.store.dispatch(SettingsActions.toggleAllPartners());
    }

    loadOrganisation(slug: string): void {
        this.store.dispatch(SettingsActions.loadOrganisation({ slug }));
    }

    setCurrentOrganisation(id: number): void {
        this.store.dispatch(SettingsActions.setCurrentOrganisation({ id }));
    }

    setInitialSelectedPartners(): void {
        this.store.dispatch(SettingsActions.setInitialSelectedPartners());
    }

    applySelectedPartnersChanges(): void {
        this.store.dispatch(SettingsActions.applySelectedPartnersChanges());
    }
}
