import { createFeatureSelector, createSelector, Store } from '@ngrx/store';
import { PartnerState } from '../reducers';
import { IPartnerState } from './partner.reducer';
import { Injectable } from '@angular/core';

export const getFeatureState = createFeatureSelector<PartnerState>('partner');

export const getPartnerState = createSelector(getFeatureState, (state: PartnerState) => state.partner);

export const getPartner = createSelector(getPartnerState, (state: IPartnerState) => state.partner);

export const getLoading = createSelector(getPartnerState, (state: IPartnerState) => state.loading);

@Injectable({
    providedIn: 'root'
})
export class PartnerSelectors {
    // selectors$
    partner$ = this.store.select(getPartner);
    getLoading$ = this.store.select(getLoading);

    constructor(private store: Store<PartnerState>) {}
}
