import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { OverlayRef } from '@angular/cdk/overlay';
import { Observable, Subject } from 'rxjs';
import { SettingsSelectors } from '@store/settings';
import { IOrganisation } from '@models/organisation';
import { AppCoreSelectors } from '@store/app-core';

@Component({
    selector: 'app-logo',
    templateUrl: './logo.component.html',
    encapsulation: ViewEncapsulation.None,
    exportAs: 'app-logo'
})
export class LogoComponent implements OnInit, OnDestroy {
    @Input() type: 'icon' | 'logo' = 'logo';

    isOpened = false;

    organisation$: Observable<IOrganisation>;

    metainfo$: Observable<any>;

    private _overlayRef: OverlayRef;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(private settingsSelectors: SettingsSelectors, private coreSelectors: AppCoreSelectors) {
        this.metainfo$ = coreSelectors.metainfo$;
        this.organisation$ = settingsSelectors.organisation$;
    }

    ngOnInit(): void {}

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}
