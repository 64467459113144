import { Injectable } from '@angular/core';
import { ApiHttpService } from '@core/services/services/api-http.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResourceService } from '@core/services/resource/resource.service';
import { QueryStringParameters } from '@core/services/services/query-string-parameters';
import { SuccessResponseType } from '@core/types/http/respone.type';
import { ListableResponseType } from '@core/types/http/listable-response.type';
import { IDevice } from '@models/device';

@Injectable({
    providedIn: 'root'
})
export class PartnerDevicesService extends ResourceService {
    constructor(protected httpService: ApiHttpService) {
        super();
    }

    loadDevices(
        partner: string | number,
        params: { [key: string]: any } = {},
        filters: { param: string; operator: string; value: any }[] = []
    ): Observable<ListableResponseType<IDevice>> {
        return this.httpService
            .get<any>(
                this.createUrlWithQueryParameters(`partner/${partner}/devices`, (qs: QueryStringParameters) => {
                    for (const key of Object.keys(params)) {
                        qs.push(key, params[key]);
                    }

                    for (const filter of filters) {
                        qs.push(filter.param, filter.value, filter.operator);
                    }
                })
            )
            .pipe(map((response: any) => response.data));
    }

    loadDevice(partner: string | number, deviceId: number, params: { [key: string]: any } = {}): Observable<IDevice> {
        return this.httpService
            .get<any>(
                this.createUrlWithQueryParameters(
                    `partner/${partner}/devices/${deviceId}`,
                    (qs: QueryStringParameters) => {
                        for (const key of Object.keys(params)) {
                            qs.push(key, params[key]);
                        }
                    }
                )
            )
            .pipe(map((response: SuccessResponseType<IDevice>) => response.data));
    }

    createDevice(partner: string, data?: any): Observable<IDevice> {
        return this.httpService
            .post(this.createUrl(`partner/${partner}/devices`), data)
            .pipe(map((response: any) => response.data));
    }

    attachDevice(partner: string, deviceId: number, data?: any): Observable<IDevice> {
        return this.httpService
            .post(this.createUrl(`partner/${partner}/devices/${deviceId}/attach`), data)
            .pipe(map((response: any) => response.data));
    }

    detachDevice(partner: string, deviceId: number, data?: any): Observable<IDevice> {
        return this.httpService
            .delete(
                this.createUrlWithQueryParameters(
                    `partner/${partner}/devices/${deviceId}/detach`,
                    (qs: QueryStringParameters) => {
                        for (const key of Object.keys(data)) {
                            qs.push(key, data[key]);
                        }
                    }
                )
            )
            .pipe(map((response: any) => response.data));
    }

    updateDevice(partner: string, deviceId: number, data?: any): Observable<IDevice> {
        return this.httpService
            .put(this.createUrl(`partner/${partner}/devices/${deviceId}`), data)
            .pipe(map((response: any) => response.data));
    }
}
