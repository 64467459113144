import { Component, Input } from '@angular/core';
import { IDevice } from '@models/device';

@Component({
    selector: 'app-device-tags',
    templateUrl: 'device-tags.component.html'
})
export class DeviceTagsComponent {
    @Input() device: IDevice;
}
