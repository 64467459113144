import { IAddress } from '@models/address';
import { IContact } from '@models/contact';
import { IHub } from '@models/warehouse';
import { IUser } from '@models/user';
import { IPartnerService } from '@models/partner-service';
import { IOrganisation } from '@models/organisation';

export interface IPartner {
    display_name: string;
    id: number;
    logo_path?: string;
    icon_path?: string;
    style_path?: string;
    slug: string;
    properties: any;
    clients?: any[];
    managers?: any[];
    assigned_users?: number[];
    organisations?: IOrganisation[];
    users?: IUser[];
    in_charge_manager_id: number;
    hubs?: IHub[];
    partner_services?: IPartnerService[];
    address?: IAddress;
    contact?: IContact;
    manager_on_shift?: {
        id: number;
        name: string;
        avatar: string;
    };
    manager_in_charge?: {
        id: number;
        name: string;
        email: string;
        avatar: string;
    };

    partner_settings?: { [key: string]: any };

    is_selected?: boolean;
}

export class Partner implements IPartner {
    display_name: string;
    id: number;
    logo_path?: string;
    icon_path?: string;
    style_path?: string;
    slug: string;
    properties: any;
    clients?: any[];
    managers?: any[];
    users?: IUser[];
    in_charge_manager_id: number;
    hubs?: IHub[];
    partner_services?: IPartnerService[];
    manager_on_shift?: {
        id: number;
        name: string;
        avatar: string;
    };
    manager_in_charge?: {
        id: number;
        email: string;
        name: string;
        avatar: string;
    };

    constructor(partner?: IPartner) {
        this.in_charge_manager_id = partner?.in_charge_manager_id || null;
        this.logo_path = partner?.logo_path || null;
        this.icon_path = partner?.icon_path || null;
        this.display_name = partner?.display_name || '';
        this.hubs = partner?.hubs || [];
        this.partner_services = partner?.partner_services || [];
    }
}
