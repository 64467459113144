import { Component, Input, OnChanges } from '@angular/core';
import { IDevice } from '@models/device';

@Component({
    selector: 'app-device-preview-battery',
    template: ` <div class="flex flex-col items-center">
        <mat-icon svgIcon="logivice:battery_problem" class="text-gray-500" [matTooltip]="tooltip"></mat-icon>
        <span *ngIf="expanded" class="text-sm">No</span>
    </div>`
})
export class DevicePreviewBatteryComponent implements OnChanges {
    @Input() device: IDevice;
    @Input() expanded = true;
    tooltip = null;

    ngOnChanges(): void {
        if (!this.expanded) {
            this.tooltip = 'Temporary unavailable';
        }
    }
}
