import { Pipe, PipeTransform } from '@angular/core';
import { IContact } from '@models/contact';

@Pipe({
    name: 'contact'
})
export class ContactPipe implements PipeTransform {
    transform(contact: IContact): string {
        const contactString = [`${contact.first_name} ${contact.last_name}`, contact.email, contact.phone]
            .filter(p => !!p)
            .join(', ');

        return `${contactString}`;
    }
}
