import { createAction, props } from '@ngrx/store';
import { ErrorResponseType } from '@core/types/http/respone.type';
import { IPartner } from '@models/partner';

export const loadPartner = createAction('[Partner] Load Partner', props<{ slug: string }>());
export const loadPartnerSuccess = createAction('[Partner] Load Partner Success', props<{ partner: IPartner }>());
export const loadPartnerFailure = createAction(
    '[Partner] Load Partner Failure',
    props<ErrorResponseType<Array<string>>>()
);
export const updatePartnerProperties = createAction(
    '[Partner] Update Partner Props',
    props<{ properties: { [key: string]: string } }>()
);
