export const environment = {
    production: true,
    type: 'qa',
    apiEndpoint: 'https://qa-api.logivice.com',
    googleMapsKey: 'AIzaSyAJDH51HcSf5jFj2O1pNn5QL3YhnpVmGVw',
    hereMapsKey: 'Ql4zUUu5n5n1doYBDKn1iCKulYntNGHrsokJkVWy0oc',
    sentryUrl: 'https://12807686807d45518c7c4d1cde5b98e7@o111583.ingest.sentry.io/4504694519037952',
    release: {
        version: '1.0.0'
    },
    pusher: {
        options: {
            key: '19f20838-8b09-4f7f-b4b5-214d1c2c80c8',
            host: 'production-dab38aa9-c454-471f-901c-94b1aecc9e39.unilog.workers.dev',
            port: 443
        }
    }
};
