import { Injectable } from '@angular/core';
import { ApiHttpService } from '@core/services/services/api-http.service';
import { Observable } from 'rxjs';
import { QueryParams, ResourceService } from '@core/services/resource/resource.service';
import { QueryStringParameters } from '@core/services/services/query-string-parameters';
import { map } from 'rxjs/operators';
import { IPagination } from '@models/pagination';
import { INotification, INotificationSubscription, ISectionedNotificationSubscriptions } from '@models/notification';
import { NotifiableModule } from '../../../modules/common/notifications-settings/notifications-settings.store';

@Injectable({
    providedIn: 'root'
})
export class NotificationService extends ResourceService {
    constructor(protected httpService: ApiHttpService) {
        super();
    }

    loadNotifications(): Observable<IPagination<INotification>> {
        return this.httpService
            .get<any>(this.createUrlWithQueryParameters('notifications', (qs: QueryStringParameters) => {}))
            .pipe(map((response: any) => response.data));
    }

    deleteNotification(id: string): Observable<INotification> {
        return this.httpService
            .delete(this.createUrl(`notifications/${id}`), {})
            .pipe(map((response: any) => response.data));
    }

    deleteAllNotifications(): Observable<any> {
        return this.httpService.delete(this.createUrl('notifications'), {}).pipe(map((response: any) => response.data));
    }

    loadNotificationSubscriptions(
        module?: NotifiableModule,
        data: QueryParams = []
    ): Observable<ISectionedNotificationSubscriptions> {
        return this.httpService
            .get<any>(this.createUrl('settings/notifications' + (module ? '/' + module : ''), data))
            .pipe(map((response: any) => response.data));
    }

    loadNotificationTypes(): Observable<INotificationSubscription[]> {
        return this.httpService
            .get<any>(
                this.createUrlWithQueryParameters('settings/notifications/types', (qs: QueryStringParameters) => {})
            )
            .pipe(map((response: any) => response.data));
    }

    loadNotificationChannels(): Observable<any> {
        return this.httpService
            .get<any>(
                this.createUrlWithQueryParameters('settings/notifications/channels', (qs: QueryStringParameters) => {})
            )
            .pipe(map((response: any) => response.data));
    }

    updateNotificationSubscriptionChannels(data: any): Observable<any> {
        return this.httpService
            .post(this.createUrl('settings/notifications/subscribe'), data)
            .pipe(map((response: any) => response.data));
    }
}
