import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { NavigationItem } from '../../../layout/navigation';

@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    private _navigation: ReplaySubject<NavigationItem[]> = new ReplaySubject<NavigationItem[]>(1);

    get navigation$(): Observable<NavigationItem[]> {
        return this._navigation.asObservable();
    }

    set navigation(navigation: NavigationItem[]) {
        this._navigation.next(navigation);
    }

    setNavigation(navigation: NavigationItem[], prefix = ''): void {
        this._navigation.next(this.mapItems(navigation, prefix));
    }

    private mapItems(items: NavigationItem[], prefix = ''): NavigationItem[] {
        return items.map(item => {
            if (item.link) {
                item.link = prefix + item.link;
            }
            if (item.children) {
                this.mapItems(item.children, prefix);
            }
            return item;
        });
    }
}
