import { Route } from '@angular/router';
import { LayoutComponent } from 'app/layout/layout.component';
import { GuestGuard } from '@core/services/guards/guest.guard';
import { AuthGuard } from '@core/services/guards/auth.guard';
import { Error404Component } from './modules/errors/404/error-404.component';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { Error401Component } from './modules/errors/401/error-401.component';
import { OrganisationActivateGuard } from './organisation-wrapper/organisation-activate.guard';
import { EntranceComponent } from './modules/common/entrance/entrance.component';

export const getDataForPermission = (permission: string | string[]): any => ({
    data: {
        permissions: {
            only: Array.isArray(permission) ? permission : [permission],
            redirectTo: {
                navigationCommands: ['/errors/401'],
                navigationExtras: {
                    skipLocationChange: true
                }
            }
        }
    },
    canActivate: [NgxPermissionsGuard]
});

// @formatter:off
/* eslint-disable max-len */
export const appRoutes: Route[] = [
    {
        path: '',
        redirectTo: '/auth/login',
        pathMatch: 'full'
    },
    {
        path: 'auth',
        canActivateChild: [GuestGuard],
        component: LayoutComponent,
        data: { layout: 'entrance' },
        loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
    },
    {
        path: 'errors',
        component: LayoutComponent,
        data: { layout: 'empty' },
        children: [
            {
                path: '404',
                component: Error404Component
            },
            {
                path: '401',
                component: Error401Component
            }
        ]
    },
    {
        path: '',
        component: LayoutComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'o/:organisation',
                canActivate: [OrganisationActivateGuard],
                children: [
                    {
                        path: 'cockpit',
                        title: 'Cockpit',
                        ...getDataForPermission('cockpit'),
                        loadChildren: () => import('./modules/cockpit/cockpit.module').then(m => m.CockpitModule)
                    },
                    {
                        path: 'uops',
                        title: 'uOps',
                        ...getDataForPermission('uops'),
                        loadChildren: () => import('./modules/uops/uops.module').then(m => m.UopsModule)
                    },
                    {
                        path: 'ucontrol',
                        title: 'uControl',
                        ...getDataForPermission('ucontrol'),
                        loadChildren: () => import('./modules/ucontrol/ucontrol.module').then(m => m.UcontrolModule)
                    },
                    {
                        path: '**',
                        redirectTo: 'cockpit/dashboard'
                        // pathMatch: ''
                    }
                ]
            }
        ]
    },
    {
        path: '',
        canActivate: [AuthGuard],
        component: LayoutComponent,
        children: [
            {
                path: '',
                loadChildren: () => import('./modules/global/global.module').then(m => m.GlobalModule)
            }
        ]
    },
    {
        path: 'share',
        component: LayoutComponent,
        data: { layout: 'empty' },
        children: [
            {
                path: '',
                loadChildren: () => import('./modules/share/share.module').then(m => m.ShareModule)
            }
        ]
    },
    {
        path: '',
        component: LayoutComponent,
        data: { layout: 'entrance' },
        canActivate: [AuthGuard],
        children: [
            {
                path: 'entrance',
                component: EntranceComponent
            }
        ]
    },
    {
        path: '**',
        redirectTo: '/auth/login'
    }
];
