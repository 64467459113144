<mat-sidenav [style.width.px]="sidebarService.width" [@.disabled]="true" class="overflow-y-auto p-5 pr-2 pb-0">
    <div class="flex justify-between">
        <div class="flex flex-col space-y-2">
            <a *ngIf="sidebarService.options.closeBtn === 'inside'" class="text-[#23BEC6] text-sm font-base cursor-pointer flex items-center" (click)="cancel()">
                <mat-icon svgIcon="mat_outline:arrow_back_ios" class="text-[#23BEC6] icon-size-3"></mat-icon>
                Back
            </a>
            <div *ngIf="sidebarService.options.header" class="font-bold text-2xl pb-4">{{ sidebarService.options.header }}</div>
        </div>
        <img *ngIf="sidebarService.options.header" src="/assets/images/logo/logo.png" alt="logivice-logo" class="max-h-7 max-w-7" />
    </div>

    <ng-template #sidebarContent></ng-template>
</mat-sidenav>

<div *ngIf="sidebar.opened && sidebarService.options.closeBtn === 'outside'" class="hidden sm:block absolute z-999 top-4" [style.right.px]="sidebarService.width + 13">
    <button mat-icon-button (click)="cancel()" class="border border-solid border-gray-400">
        <mat-icon svgIcon="mat_outline:close"></mat-icon>
    </button>
</div>
