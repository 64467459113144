import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'nativeFilter'
})
export class NativeFilterPipe implements PipeTransform {
    transform(items: any[], filter: any, property: string): any[] {
        if (!items) {
            return [];
        }

        if (!filter) {
            return items;
        }

        return items.filter(item => item[property] === filter);
    }
}
