import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AuthDispatchers, AuthSelectors } from '@store/auth';
import { IUser } from '@models/user';

@Component({
    selector: 'user-menu',
    templateUrl: './user-menu.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'userMenu'
})
export class UserMenuComponent implements OnInit, OnDestroy {
    @Input() showAvatar: boolean = true;

    user$: Observable<IUser>;

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(private authDispatchers: AuthDispatchers, private authSelectors: AuthSelectors) {
        this.user$ = authSelectors.user$;
    }

    ngOnInit(): void {}

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    signOut(): void {
        this.authDispatchers.logout();
    }
}
