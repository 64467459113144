import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { AppLeafletMapComponent } from '@shared/components/leaflet-map/leaflet-map.component';
import { LeafletMarkerClusterModule } from '@asymmetrik/ngx-leaflet-markercluster';
import { LeafletDrawModule } from '@asymmetrik/ngx-leaflet-draw';

@NgModule({
    imports: [LeafletModule, LeafletMarkerClusterModule, SharedModule, LeafletDrawModule],
    declarations: [AppLeafletMapComponent],
    exports: [AppLeafletMapComponent]
})
export class LeafletMapModule {}
