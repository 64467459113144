import { Component, Input } from '@angular/core';
import { IDevice } from '@models/device';

@Component({
    selector: 'app-device-preview',
    templateUrl: 'device-preview.component.html'
})
export class DevicePreviewComponent {
    @Input() device: IDevice;
    @Input() expanded = true;
}
