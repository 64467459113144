import { Pipe, PipeTransform } from '@angular/core';
import { UserPreferencesService } from '@core/services/services/user-preferences.service';

@Pipe({
    name: 'temperature'
})
export class TemperaturePipe implements PipeTransform {
    constructor(private preferencesService: UserPreferencesService) {}

    transform(celsius: number): string {
        return this.preferencesService.formatTemperature(celsius);
    }
}
