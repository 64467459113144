import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';

import { filter, map } from 'rxjs/operators';

import { combineLatest, Observable } from 'rxjs';
import { AuthSelectors } from '@store/auth';
import { AuthService } from '@core/services/resource/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private authSelectors: AuthSelectors, private authService: AuthService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        this.authService.loadUserIfFirstInit();
        this.authService.redirectUrl = state.url;

        return combineLatest([this.authSelectors.loggedIn$, this.authSelectors.user$]).pipe(
            filter(([loggedIn, user]) => loggedIn && !!user),
            map(([loggedIn, user]) => !!user)
        );
    }
}
