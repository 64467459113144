export class QueryStringParameters {
    private paramsAndValues: string[];

    constructor() {
        this.paramsAndValues = [];
    }

    public toString = (): string => this.paramsAndValues.join('&');

    public push(key: string, value: string | number | boolean | (string | number | boolean)[], operator = '='): void {
        if (Array.isArray(value)) {
            value.forEach(item => {
                this.pushToParams(key + '[]', item, operator);
            });
        } else {
            this.pushToParams(key, value, operator);
        }
    }

    private pushToParams(key: string, value: string | number | boolean, operator): void {
        const type = typeof value;

        if (type === 'boolean') {
            value = +value;
        } else if (value === null) {
            value = null;
        } else {
            value = value.toString();
        }

        this.paramsAndValues.push([key, encodeURIComponent(value)].join(operator));
    }
}
