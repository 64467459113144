import {
    ChangeDetectionStrategy,
    Component,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation
} from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { MatButton } from '@angular/material/button';
import { Observable, Subject } from 'rxjs';
import { WebsocketService } from '@core/services/services/websocket.service';
import { TasksStore } from './tasks.store';
import { IPagination } from '@models/pagination';
import { saveAs } from 'file-saver';
import { IBackgroundTask } from '@models/background-task';
import { TaskService } from '@core/services/resource/task.service';
import { ModalDialogService } from '@shared/components/modal-dialog/modal-dialog.service';

@Component({
    selector: 'tasks',
    templateUrl: './tasks.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'tasks',
    providers: [TasksStore]
})
export class TasksComponent implements OnInit, OnDestroy {
    @ViewChild('detailsTemplate') private detailsTemplate: TemplateRef<any>;

    @ViewChild('tasksOrigin') private _tasksOrigin: MatButton;
    @ViewChild('tasksPanel') private _tasksPanel: TemplateRef<any>;
    //

    tasks$: Observable<IPagination<IBackgroundTask>>;

    count$: Observable<number>;
    private _overlayRef: OverlayRef;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(
        private _overlay: Overlay,
        private _viewContainerRef: ViewContainerRef,
        private websocketService: WebsocketService,
        public tasksStore: TasksStore,
        private taskService: TaskService,
        private modalDialogService: ModalDialogService
    ) {
        this.tasks$ = tasksStore.tasks$;
        this.count$ = tasksStore.count$;
    }

    ngOnInit(): void {
        this.tasksStore.loadTasks();
        this.websocketService
            .getPrivateChannel()
            .listen('.task.created', event => {
                this.tasksStore.addTask(event.model);
            })
            .listen('.task.updated', event => {
                this.tasksStore.updateTask(event.model);
            })
            .listen('.task.deleted', event => {
                console.info('deleted', event);
            });
    }

    downloadFile(filepath: string): void {
        this.taskService.downlaodFile(filepath).subscribe((response: any) => {
            const fileName = response.headers.get('content-disposition').split('filename=')[1].split(';')[0];

            const blob = new Blob([response.body], { type: response.body.type });
            saveAs(blob, fileName);
        });
    }

    openDetails(task: IBackgroundTask): void {
        this.modalDialogService.showDialog(this.detailsTemplate, { task: task }, 'Task details');
    }

    deleteAll(): void {
        this.tasksStore.deleteAllTasks();
    }

    delete(task: IBackgroundTask): void {
        this.tasksStore.deleteTask(task.id);
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();

        // Dispose the overlay
        if (this._overlayRef) {
            this._overlayRef.dispose();
        }
    }

    openPanel(): void {
        // Return if the messages panel or its origin is not defined
        if (!this._tasksPanel || !this._tasksOrigin) {
            return;
        }

        // Create the overlay if it doesn't exist
        if (!this._overlayRef) {
            this._createOverlay();
        }

        // Attach the portal to the overlay
        this._overlayRef.attach(new TemplatePortal(this._tasksPanel, this._viewContainerRef));
    }

    getIcon(slug): string {
        switch (slug) {
            case 'system':
                return 'heroicons_outline:terminal';
            default:
                return 'heroicons_outline:bell';
        }
    }

    getType(type: string): string {
        switch (type) {
            case 'App\\Jobs\\PartMaster\\ImportFromFile':
                return 'PartMaster import';
            case 'App\\Jobs\\PartMaster\\ExportExcel':
                return 'PartMaster export';
            case 'App\\Jobs\\Inventory\\ExportExcel':
                return 'Inventories export';
            case 'App\\Jobs\\WarehouseTransaction\\ExportExcel':
                return 'Warehouse Transactions export';
            default:
                return 'system';
        }
    }

    closePanel(): void {
        this._overlayRef.detach();
    }

    private _createOverlay(): void {
        // Create the overlay
        this._overlayRef = this._overlay.create({
            hasBackdrop: true,
            backdropClass: 'fuse-backdrop-on-mobile',
            positionStrategy: this._overlay
                .position()
                .flexibleConnectedTo(this._tasksOrigin._elementRef.nativeElement)
                .withLockedPosition()
                .withPush(true)
                .withPositions([
                    {
                        originX: 'start',
                        originY: 'bottom',
                        overlayX: 'start',
                        overlayY: 'top'
                    },
                    {
                        originX: 'start',
                        originY: 'top',
                        overlayX: 'start',
                        overlayY: 'bottom'
                    },
                    {
                        originX: 'end',
                        originY: 'bottom',
                        overlayX: 'end',
                        overlayY: 'top'
                    },
                    {
                        originX: 'end',
                        originY: 'top',
                        overlayX: 'end',
                        overlayY: 'bottom'
                    }
                ])
        });

        // Detach the overlay from the portal on backdrop click
        this._overlayRef.backdropClick().subscribe(() => {
            this._overlayRef.detach();
        });
    }
}
