import { Injectable } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ProgressBarService {
    loadingMap: Map<string, boolean> = new Map<string, boolean>();

    private _bufferValue: BehaviorSubject<number>;
    private _mode: BehaviorSubject<string>;
    private _visible: BehaviorSubject<boolean>;
    private _value: BehaviorSubject<number>;

    constructor(private _router: Router) {
        // Initialize the service
        this._init();
    }

    get bufferValue(): Observable<any> {
        return this._bufferValue.asObservable();
    }

    get mode(): Observable<any> {
        return this._mode.asObservable();
    }

    get value(): Observable<any> {
        return this._value.asObservable();
    }

    get visible(): Observable<any> {
        return this._visible.asObservable();
    }

    setBufferValue(value: number): void {
        this._bufferValue.next(value);
    }

    setMode(value: 'determinate' | 'indeterminate' | 'buffer' | 'query'): void {
        this._mode.next(value);
    }

    setValue(value: number): void {
        this._value.next(value);
    }

    show(): void {
        this._visible.next(true);
    }

    hide(): void {
        this._visible.next(false);
    }

    setLoading(loading: boolean, url: string): void {
        if (loading) {
            this.loadingMap.set(url, loading);
            this.show();
        } else if (!loading && this.loadingMap.has(url)) {
            this.loadingMap.delete(url);
        }
        if (this.loadingMap.size === 0) {
            this.hide();
        }
    }

    private _init(): void {
        // Initialize the behavior subjects
        this._bufferValue = new BehaviorSubject(0);
        this._mode = new BehaviorSubject('indeterminate');
        this._value = new BehaviorSubject(0);
        this._visible = new BehaviorSubject<boolean>(false);

        // Subscribe to the router events to show/hide the loading bar
        this._router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe(() => {
            this.show();
        });

        this._router.events
            .pipe(
                filter(
                    event =>
                        event instanceof NavigationEnd ||
                        event instanceof NavigationError ||
                        event instanceof NavigationCancel
                )
            )
            .subscribe(() => {
                this.hide();
            });
    }
}
