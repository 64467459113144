import { ComponentStore } from '@ngrx/component-store';
import { Injectable } from '@angular/core';
import { IKind } from '@models/request';
import { switchMap, tap } from 'rxjs/operators';
import { RequestService } from '@core/services/resource/request.service';

export interface RequestsListState {
    requestKinds?: IKind[];
}

@Injectable()
export class RequestCreateStore extends ComponentStore<RequestsListState> {
    readonly setRequestKinds = this.updater(
        (state: RequestsListState, requestKinds: IKind[]) => ({
            ...state,
            requestKinds
        })
    );

    readonly requestKinds$ = this.select(state => state.requestKinds);

    readonly loadRequestKinds = this.effect<void>(trigger$ =>
        trigger$.pipe(
            switchMap(() =>
                this.requestService
                    .loadRequestKinds()
                    .pipe(tap({ next: kinds => this.setRequestKinds(kinds) }))
            )
        )
    );

    constructor(private requestService: RequestService) {
        super({});
    }
}
