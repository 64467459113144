<button (click)="openPanel()" #mapDialogOrigin type="button" class="flex items-center w-full">
    <ng-content></ng-content>
</button>

<!-- Shortcuts panel -->
<ng-template #mapDialogPanel>
    <div class="fixed inset-0 sm:static sm:inset-auto flex flex-col sm:rounded-md overflow-hidden shadow-lg ng-star-inserted sm:min-w-90 sm:w-90">
        <!-- Header -->
        <div class="flex shrink-0-0 items-center py-2 pr-4 pl-6 bg-accent text-on-accent sm:hidden">
            <div class="text-lg font-medium leading-10">See on map</div>
            <div class="ml-auto">
                <button mat-icon-button (click)="closePanel()">
                    <mat-icon class="icon-size-5 text-current" [svgIcon]="'heroicons_solid:x'"></mat-icon>
                </button>
            </div>
        </div>

        <div class="relative flex flex-col flex-auto sm:max-h-120 divide-y overflow-y-auto bg-card p-4 h-100">
            <app-common-leaflet-map [mapConfiguration]="mapConfiguration" class="max-h-full h-full"></app-common-leaflet-map>
        </div>
    </div>
</ng-template>
