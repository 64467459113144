import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@store/reducers';
import * as AppCoreActions from './app-core.actions';
import { Scheme } from '@store/app-core/app.themes';

@Injectable()
export class AppCoreDispatchers {
    constructor(private store: Store<AppState>) {}

    changeOrientation(orientation: 'horizontal' | 'vertical'): void {
        this.store.dispatch(AppCoreActions.changeOrientation({ orientation }));
    }

    changeTheme(theme: string): void {
        this.store.dispatch(AppCoreActions.changeTheme({ theme }));
    }

    changeScheme(scheme: Scheme, silent: boolean = false): void {
        this.store.dispatch(AppCoreActions.changeScheme({ scheme, silent }));
    }

    setNavigation(navigation: 'partner' | 'threepl' | 'cockpit'): void {
        this.store.dispatch(AppCoreActions.setNavigation({ navigation }));
    }

    changeToolbar(toolbar: { background?: string; hidden?: boolean }): void {
        this.store.dispatch(AppCoreActions.changeToolbar({ toolbar }));
    }

    changeSidepanel(sidepanel: { hidden?: boolean }): void {
        this.store.dispatch(AppCoreActions.changeSidepanel({ sidepanel }));
    }

    changeNavbar(navbar: {
        primaryBackground?: string;
        secondaryBackground?: string;
        folded?: boolean;
        hidden?: boolean;
    }): void {
        this.store.dispatch(AppCoreActions.changeNavbar({ navbar }));
    }

    changeMetainfo(metainfo: {
        subject?: string;
        logo?: string;
        icon?: string;
        type?: 'uops' | 'threepl' | 'cockpit' | 'ucontrol' | null;
        slug?: string;
    }): void {
        this.store.dispatch(AppCoreActions.changeMetainfo({ metainfo }));
    }

    toggleNavbar(): void {
        this.store.dispatch(AppCoreActions.toggleNavbar());
    }

    navigateToDashboard(): void {
        this.store.dispatch(AppCoreActions.navigateToDashboard());
    }

    navigateToUrl(url: string): void {
        this.store.dispatch(AppCoreActions.navigateToUrl({ url }));
    }

    loadMentions(): void {
        this.store.dispatch(AppCoreActions.loadMentions());
    }

    setTitleVars(vars: object): void {
        this.store.dispatch(AppCoreActions.setTitleVars({ vars }));
    }

    resetTitleVars(): void {
        this.store.dispatch(AppCoreActions.setTitleVars({ vars: null }));
    }
}
