import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import { IPartner } from '@models/partner';
import { IThreepl } from '@models/threepl';
import { AuthDispatchers } from '@store/auth';
import { SettingsDispatchers, SettingsSelectors } from '@store/settings';
import { IOrganisation } from '@models/organisation';
import { map, take } from 'rxjs/operators';
import { FuseDrawerComponent } from '../../../../@fuse/components/drawer';
import { AppCoreSelectors } from '@store/app-core';
import { EnterAsSectionComponent } from './section/section.component';
import { NgxPermissionsService } from 'ngx-permissions';
import { Router } from '@angular/router';

@Component({
    selector: 'enter-as',
    templateUrl: './enter-as.component.html',
    styleUrls: ['./enter-as.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class EnterAsComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() directLinks = false;

    @ViewChild('enterAsDrawer') enterAsDrawer: FuseDrawerComponent;
    @ViewChild('enterAsSectionComponent') enterAsSectionComponent: EnterAsSectionComponent;

    sidebarFolded: boolean;

    selected: string | null = 'uops';

    allowedPartners$: Observable<IPartner[]>;
    threepls$: Observable<IThreepl[]>;
    organisation$: Observable<IOrganisation>;

    selectedPartners$: Observable<IPartner[]>;
    // selectedPartnersIds$: Observable<number[]>;
    selectedPartnersIds$: Observable<number[]>;
    submittingSelected$: Observable<boolean>;

    filter: string = '';

    // Private
    private _unsubscribeAll: Subject<any>;

    constructor(
        private _authDispatchers: AuthDispatchers,
        private _settingsSelectors: SettingsSelectors,
        private _settingsDispatchers: SettingsDispatchers,
        private appCoreSelectors: AppCoreSelectors,
        private permissionsService: NgxPermissionsService,
        private _router: Router
    ) {
        this.selectedPartners$ = _settingsSelectors.selectedPartners$;
        this.allowedPartners$ = _settingsSelectors.allowedPartners$;
        this.organisation$ = _settingsSelectors.organisation$;
        this.threepls$ = _settingsSelectors.allowedThreepls$;
        this.selectedPartnersIds$ = _settingsSelectors.selectedPartnersIds$;
        this.submittingSelected$ = _settingsSelectors.submittingSelected$;

        // Set the defaults
        this.sidebarFolded = true;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    get displayEnterAs$(): Observable<boolean> {
        return combineLatest([this.allowedPartners$, this.permissionsService.permissions$]).pipe(
            map(([allowedPartners, permissions]) => {
                const allowedModules = [...new Set(Object.keys(permissions).filter(p => !p.includes('.')))];
                return allowedPartners.length > 1 || allowedModules.length > 1;
            })
        );
    }

    ngOnInit(): void {
        // Subscribe to the foldedChanged observable
    }

    ngAfterViewInit(): void {
        this.setDefaults();
        this.enterAsDrawer?.openedChanged.subscribe(s => {
            if (s) {
                this.setDefaults();
            }
        });
    }

    setDefaults(): void {
        this.appCoreSelectors.type$.pipe(take(1)).subscribe(t => {
            setTimeout(() => {
                if (this.enterAsSectionComponent) {
                    this.enterAsSectionComponent.selected = t;
                }
                this.selected = t;
            });
        });
        this._settingsDispatchers.setInitialSelectedPartners();
    }

    goToEntrance(): void {
        this._router.navigate(['/entrance']);
    }

    signOut(): void {
        this._authDispatchers.logout();
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}
