import {
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation
} from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { Subject } from 'rxjs';
import { TemplatePortal } from '@angular/cdk/portal';
import { MapConfiguration } from '@shared/components/leaflet-map/map-configuration';

@Component({
    selector: 'map-dialog',
    templateUrl: './map-dialog.component.html',
    encapsulation: ViewEncapsulation.None
})
export class AppMapDialogComponent implements OnInit, OnDestroy {
    @Input() point: { lat: number; lng: number };

    @ViewChild('mapDialogOrigin') private _mapDialogOrigin: ElementRef;
    @ViewChild('mapDialogPanel') private _mapDialogPanel: TemplateRef<any>;

    mapConfiguration: MapConfiguration;

    private _overlayRef: OverlayRef;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(private _overlay: Overlay, private _viewContainerRef: ViewContainerRef) {
        this.mapConfiguration = new MapConfiguration();
    }

    ngOnInit(): void {
        this.mapConfiguration
            .updateConfiguration({
                points: [
                    {
                        ref: 'current',
                        position: this.point
                    }
                ]
            })
            .display();
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();

        // Dispose the overlay
        if (this._overlayRef) {
            this._overlayRef.dispose();
        }
    }

    openPanel(): void {
        // Return if the messages panel or its origin is not defined
        if (!this._mapDialogPanel || !this._mapDialogOrigin) {
            return;
        }

        // Create the overlay if it doesn't exist
        if (!this._overlayRef) {
            this._createOverlay();
        }

        // Attach the portal to the overlay
        this._overlayRef.attach(new TemplatePortal(this._mapDialogPanel, this._viewContainerRef));
    }

    closePanel(): void {
        this._overlayRef.detach();
    }

    private _createOverlay(): void {
        // Create the overlay
        this._overlayRef = this._overlay.create({
            hasBackdrop: true,
            backdropClass: 'fuse-backdrop-on-mobile',
            positionStrategy: this._overlay
                .position()
                .flexibleConnectedTo(this._mapDialogOrigin.nativeElement)
                .withLockedPosition()
                .withPush(true)
                .withPositions([
                    {
                        originX: 'start',
                        originY: 'bottom',
                        overlayX: 'start',
                        overlayY: 'top'
                    },
                    {
                        originX: 'start',
                        originY: 'top',
                        overlayX: 'start',
                        overlayY: 'bottom'
                    },
                    {
                        originX: 'end',
                        originY: 'bottom',
                        overlayX: 'end',
                        overlayY: 'top'
                    },
                    {
                        originX: 'end',
                        originY: 'top',
                        overlayX: 'end',
                        overlayY: 'bottom'
                    }
                ])
        });

        // Detach the overlay from the portal on backdrop click
        this._overlayRef.backdropClick().subscribe(() => {
            this._overlayRef.detach();
        });
    }
}
