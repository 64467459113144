import { Component, effect, EventEmitter, Input, Output } from '@angular/core';
import { RequestPulseComponent } from '@shared/components/request-pulse/request-pulse.component';
import { FormGroup } from '@angular/forms';

/**
 * Button component:
 * includes request-pulse (loading animation)
 * double-click protection / prevents request duplications
 * disables button and form (fields) while submitting
 */
@Component({
    selector: 'action-button',
    templateUrl: './action-button.component.html'
})
export class ActionButtonComponent extends RequestPulseComponent {
    @Input() form: FormGroup;
    @Output() action = new EventEmitter<void>();

    effect = effect(() => {
        if (this.loading() === false) {
            this.form?.enable();
        }
    });

    emit(): void {
        if (!this.loading()) {
            this.form?.disable();
            this.action.emit();
        }
    }
}
